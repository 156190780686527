export const confirmationModalContainer = {
  '.modal-container': {
    backgroundColor: 'var(--background-stronger)',
    '.modal-title': {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      padding: '0 1.5rem 2rem 1.5rem',
      borderBottom: '1px solid var(--border-color)',
      color: 'var(--gray-200)'
    },
    '.modal-body': {
      padding: '2.75rem 2.5rem 1rem 2.5rem',
    },
    '.action-container': {
      display: 'flex',
      justifyContent: 'flex-end',
      padding: '2rem 1.5rem 0 1.5rem',
      borderTop: '1px solid var(--border-color)',
      '.close-button': {
        marginRight: 12,
        '&:hover': {
          textDecoration: 'underline',
        },
      },
    },
  },
};
