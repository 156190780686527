import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { checkButtonContainer, switchToggle } from './styles';

const SwitchButton = (props) => {
  const { label, className, value = false, onChange = () => {}, size = 24, color } = props;
  const [isChecked, setIsChecked] = useState(!!value);

  useEffect(() => {
    setIsChecked(value);
  }, [value]);

  const handleChange = () =>
    setIsChecked((prev) => {
      onChange(!prev);
      return !prev;
    });

  return (
    <div
      role="checkbox"
      tabIndex={0}
      css={checkButtonContainer(!!label, props)}
      {...(className && { className })}
      onClick={handleChange}
      aria-checked={isChecked}>
      <span
        css={switchToggle(
          {
            width: size,
            smallWidth: size - size / 4,
            padding: size / 8,
          },
          color,
          isChecked,
        )}
        className="input-indicator"
      />
      <span className="label">{label}</span>
    </div>
  );
};

SwitchButton.propTypes = {
  label: PropTypes.any,
  className: PropTypes.object,
  value: PropTypes.bool,
  onChange: PropTypes.func,
  size: PropTypes.number,
  color: PropTypes.string,
};

export default SwitchButton;
