import PropTypes from 'prop-types';
import { ColorBatch, Icon } from 'components/ui';
import { badge, channelListItem } from './styles';
import { Avatar } from 'components/shared';
import { useMessagesStore } from 'stores';

const ChannelListItem = (props) => {
  const { channel, isSelected, onChatOpen, isMember = true } = props;
  const { messages } = useMessagesStore();

  const handleOpenChat = () => {
    onChatOpen(channel.id);
  };

  const getUnreadMessagesText = (count) => {
    if (count >= 1 && count <= 9) return `0${count}`;
    else if (count > 99) return '99+';

    return count;
  };

  const getFirstSymbol = (val) => {
    // Check if name starts with emoji
    const firstSymbolEmojiRegex = /^\p{RGI_Emoji}/gv;
    if (firstSymbolEmojiRegex.test(val)) return val.match(firstSymbolEmojiRegex)[0] ?? 'C';

    return val[0]?.toUpperCase();
  };

  return (
    <li key={channel.id} role="presentation" css={channelListItem(isSelected, isMember)} onClick={handleOpenChat}>
      <div className="channel-info">
        {channel?.icon ? (
          <Avatar picThumbnailUrl={channel.icon} />
        ) : (
          <div className="letter-avatar">
            <div>{getFirstSymbol(channel.name)}</div>
          </div>
        )}
        <div className="channel-name">
          {channel?.public ? (
            isMember ? (
              <Icon iconName="tag" size={14} />
            ) : (
              <Icon iconName="add" size={14} />
            )
          ) : (
            <Icon iconName="lock" size={14} />
          )}
          <p>{channel?.name}</p>
        </div>
      </div>

      <div className="channel-tags">
        {messages[channel.id]?.unreadCount > 0 && (
          <ColorBatch withoutStartCase type="error" css={badge}>
            {getUnreadMessagesText(messages[channel.id].unreadCount)}
          </ColorBatch>
        )}
      </div>
    </li>
  );
};

ChannelListItem.propTypes = {
  channel: PropTypes.object,
  isSelected: PropTypes.bool,
  isMember: PropTypes.bool,
  onChatOpen: PropTypes.func,
};

export default ChannelListItem;
