import { commonStyles } from 'styles';

export const modalContainer = {
  backgroundColor: 'rgba(0, 0, 0, 0.5)',
  overflow: 'hidden auto',
  display: 'block',
};

export const modalScrollContainer = ({ large, small, fullscreen }) => [
  {
    minHeight: 'calc(100vh - 3.5em)',
    maxWidth: 500,
    margin: '1.75em auto',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  large && {
    '@media (min-width: 992px)': {
      maxWidth: 800,
    },
  },
  small && {
    '@media (min-width: 576px)': {
      maxWidth: 300,
    },
  },
  fullscreen && {
    '@media (min-width: 576px)': {
      maxWidth: '90%',
    },
  },
];

export const modalContentContainer = (props) => [
  {
    display: 'flex',
    flexFlow: 'column nowrap',
    justifyContent: 'flex-start',
    width: '100%',
    position: 'relative',
    backgroundColor: 'var(--background-stronger)',
    border: '1px solid var(--border-color)',
    padding: '1.5em 0',
    borderRadius: 6,
  },
  ...commonStyles(props),
];
