import PropTypes from 'prop-types';
import { isFunction } from 'lodash-es';
import { commonStyles } from '../../../styles';
import { backgroundPicture, imageContainer, child } from './styles';

const Image = (props) => {
  const { children, src, alt, width, height, background, className, onClick, ...restProps } = props;

  return background ? (
    <div
      title={alt}
      css={backgroundPicture(props)}
      {...(className && { className })}
      {...(isFunction(onClick) && {
        onClick,
        tabIndex: 0,
        role: 'button',
      })}
      {...restProps}>
      {children}
    </div>
  ) : children ? (
    <div
      {...(className && { className })}
      css={commonStyles(props)}
      {...(isFunction(onClick) && {
        onClick,
        tabIndex: 0,
        role: 'button',
      })}
      {...restProps}>
      <img src={src} alt={alt} width={width || '100%'} height={height || 'auto'} css={imageContainer(props)} />
      <div css={child(props)}>{children}</div>
    </div>
  ) : (
    <img
      src={src}
      alt={alt}
      width={width || '100%'}
      height={height || 'auto'}
      {...(isFunction(onClick) && {
        onClick,
        tabIndex: 0,
        role: 'button',
      })}
      {...(className && { className })}
      css={imageContainer(props)}
    />
  );
};

Image.propTypes = {
  children: PropTypes.any,
  background: PropTypes.bool,
  src: PropTypes.string,
  alt: PropTypes.string,
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  className: PropTypes.string,
  onClick: PropTypes.func,
};

export default Image;
