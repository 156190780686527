export const settingsContainer = {
  position: 'relative',
  height: '100vh',
  overflow: 'overlay',
  padding: 24,
  backgroundColor: 'var(--background-stronger)',
  minWidth: 380,
  maxWidth: 380,

  '@media (max-width: 991px)': {
    minWidth: '100%',
    maxWidth: '100%',
  },

  '.page-title': {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },

  '.name': {
    marginBottom: '0.25rem',
    fontSize: '1.5rem',
  },

  '.edit-icon': {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '3.5rem',
    height: '3.5rem',
  },

  '.user-container': {
    padding: 24,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'relative',

    '.avatar-image-container': {
      position: 'relative',
      '.edit-icon': {
        backgroundColor: 'var(--gray-400)',
        position: 'absolute',
        top: 62,
        right: 0,
        borderRadius: '50%',
        '&:hover': {
          backgroundColor: 'var(--gray-500)',
        },
        '&:active': {
          color: 'var(--gray-200)',
        },
      },

      '.avatar-image': {
        width: 96,
        height: 96,
        border: '1px solid var(--border-color)',
        padding: '0.25rem',
        borderRadius: '50%',
        marginBottom: 24,
      },
    },

    '.status-container': {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      backgroundColor: 'transparent',
      cursor: 'pointer',
      '.status': {
        color: 'var(--secondary-weaker)',
        marginRight: 3,
      },
    },
  },

  '.profile-description': {
    padding: '24px 0',

    '.user-content-container': {
      backgroundColor: 'var(--theme-color)',
      border: '1px solid var(--border-color-weak)',
      borderTop: 'none',
      position: 'relative',

      '.user-content': {
        padding: '1.25rem 1.5rem',

        '.label': {
          color: 'var(--secondary)',
          marginBottom: 4,
        },
      },

      '.edit-button-container': {
        position: 'absolute',
        top: 10,
        right: 10,
        backgroundColor: 'var(--gray-400)',
        display: 'flex',
        alignItems: 'center',
        paddingRight: 10,
        borderRadius: '5px',
        cursor: 'pointer',
        '&:hover': {
          backgroundColor: 'var(--gray-400)',
        },
      },
    },

    '.help-container': {
      backgroundColor: 'var(--theme-color)',
      border: '1px solid var(--border-color-weak)',
      borderTop: 'none',
      marginBottom: 4,
      padding: 16,
      height: 300,
      overflow: 'auto',

      '.scrollable-container::-webkit-scrollbar': {
        display: 'none',
      },
    },
  },
};
