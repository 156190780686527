import PropTypes from 'prop-types';
import { Image } from 'components/ui';
import { imageStyles } from './styles';

const Slide = (props) => {
  const { children, image, width, height, className } = props;

  return (
    <Image
      src={image}
      alt="carousel-images"
      width={width}
      height={height}
      css={imageStyles}
      {...(className && { className })}>
      {children}
    </Image>
  );
};

Slide.propTypes = {
  children: PropTypes.any,
  image: PropTypes.string,
  color: PropTypes.string,
  fontSize: PropTypes.number,
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  className: PropTypes.string,
};

export default Slide;
