import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslations } from '@veraio/strank';
import { hasAtLeastOneRole } from '@oneecosystem/authenticate';
import { setCurrentChat, useChatStore, useUserStore, resetUnreadMessages } from 'stores';
import { createDirectChat, removeUsersFromChannel, removeUsersFromGroup } from 'services';
import { ActionsContainer, Avatar, Collapse, ConfirmationModal, Icon } from 'components';
import { userChatStatusMap, chatTypes } from 'enums';
import { chatInfo, userInfo } from './config';
import { chatInfoContainer } from './styles';

const restrictedChannels = ['General', 'Support', 'KYC', 'Compliance'];

const ChatInfo = (props) => {
  const { onClose } = props;
  const { getText } = useTranslations();
  const { currentChat, chats } = useChatStore();
  const user = useUserStore();
  const [members, setMembers] = useState([]);
  const [openContainer, setOpenContainer] = useState({ fileActions: false, memberActions: false });
  const [openModal, setOpenModal] = useState({ removeMember: false, user: null });
  const isAdmin = hasAtLeastOneRole(['Admin', 'SuperAdmin']);
  const isModeratorAgent = hasAtLeastOneRole(['Agent', 'Moderator']);

  useEffect(() => {
    // Set chat members if we are in group or channel chat
    currentChat?.users && setMembers(currentChat?.users ?? []);
  }, [currentChat]);

  const hideContainer = () => setOpenContainer({ fileActions: false, memberActions: false });

  const toggleMemberActionsContainer = (id) => {
    setOpenContainer((prev) => ({
      ...prev,
      memberActions: !prev.memberActions,
      openElement: !prev.memberActions && id,
    }));
  };

  const handleOpenChatWithUser = (userId) => {
    const foundChat = chats?.find((chat) => chat.user === userId);

    if (!foundChat) {
      setCurrentChat(userId, chatTypes.chat);
      createDirectChat({ user: userId });
    } else {
      setCurrentChat(foundChat.id, chatTypes.chat);
      resetUnreadMessages(userId);
    }

    setOpenContainer({ fileActions: false, memberActions: false });
    onClose();
  };

  const handleOpenRemoveMemberModal = (userData) => {
    setOpenModal({ removeMember: true, user: userData });
  };

  const handleCloseModal = () => {
    setOpenModal({});
  };

  const handleRemoveMemberFromChat = () => {
    const data = { id: currentChat.id, users: [openModal.user.id] };

    if (currentChat?.targetType === chatTypes.group) removeUsersFromGroup(data, handleCloseModal);
    else if (currentChat?.targetType === chatTypes.channel) removeUsersFromChannel(data, handleCloseModal);
  };

  const getFirstSymbol = (val) => {
    // Check if name starts with emoji
    const firstSymbolEmojiRegex = /^\p{RGI_Emoji}/gv;
    if (firstSymbolEmojiRegex.test(val))
      return val.match(firstSymbolEmojiRegex)[0] ?? currentChat?.targetType[0]?.toUpperCase();

    return val[0]?.toUpperCase();
  };

  const getUserDisplayName = (userData) => {
    let displayName = userData.first_name;

    if (userData.last_name) displayName = `${displayName && displayName + ' '}${userData.last_name}`;

    return displayName || userData.username;
  };

  const checkCanRemoveMember = (member) => {
    if (currentChat.owner.id === member.id) return false;
    if (isAdmin) return true;

    if (isModeratorAgent) {
      return !member.roles.find(
        (role) => role === 'SuperAdmin' || role === 'Admin' || role === 'Moderator' || role === 'Agent',
      );
    }
  };

  return (
    <div css={chatInfoContainer(userChatStatusMap[currentChat?.userStatus]?.color)}>
      <div className="page-title">
        <Icon iconName="close" color="secondary" onClick={onClose} />
      </div>

      <div className="user-container">
        {currentChat?.targetType === chatTypes.group ||
        (currentChat?.targetType === chatTypes.channel && !currentChat?.icon) ? (
          <div className="letter-avatar">
            <div>{getFirstSymbol(currentChat?.name)}</div>
          </div>
        ) : currentChat?.targetType === chatTypes.channel ? (
          <Avatar border width={96} picThumbnailUrl={currentChat?.icon} />
        ) : (
          <Avatar
            border
            width={96}
            picThumbnailUrl={currentChat?.user?.pictureUrl}
            status={currentChat?.user?.status}
          />
        )}
        <h5 className="name">{currentChat?.name}</h5>
        <div className="status-container">
          <div className="status-indicator" />
          {currentChat?.userStatus && <p className="status">{currentChat?.userStatus}</p>}
        </div>
      </div>

      <div className="profile-description">
        {currentChat?.description && <p className="motto">{currentChat.description}</p>}

        <Collapse header={getText('about')} cardIconName="person">
          <div className="user-content-container">
            {(currentChat?.targetType === chatTypes.chat ? userInfo(currentChat.user) : chatInfo(currentChat))?.map(
              (el, ind) => (
                <div key={ind} className="user-content">
                  <p className="label">{getText(el.label)}</p>
                  <h5 className="name">{el.value ?? '-'}</h5>
                </div>
              ),
            )}
          </div>
        </Collapse>

        {currentChat && currentChat.targetType !== chatTypes.chat && (
          <>
            <Collapse
              disabled={currentChat.targetType === chatTypes.channel && restrictedChannels.includes(currentChat.name)}
              header={`${getText('members')} (${members.length ?? 0})`}
              cardIconName="group">
              <div className="members-container">
                {members.map((member, ind) => (
                  <div key={ind} className="member-content">
                    <div className="member-info-container">
                      <div className="member-image-container">
                        <Avatar pictureUrl={member?.pictureUrl} />
                      </div>
                      <div>
                        <h5 className="name">{getUserDisplayName(member)}</h5>
                        {member?.role && <span>({member.role})</span>}
                      </div>
                    </div>
                    {member?.email !== user.email?.toLowerCase() && (
                      <div>
                        <Icon
                          iconName="more_horiz"
                          color="secondary"
                          size={24}
                          onClick={() => toggleMemberActionsContainer(member.id)}
                        />
                        {openContainer.memberActions && openContainer.openElement === member.id && (
                          <ActionsContainer
                            styles={{ top: 36, right: 10, width: 170, background: 'background-stronger' }}
                            onBlur={hideContainer}>
                            <div
                              role="button"
                              tabIndex={0}
                              onClick={() => handleOpenChatWithUser(member.id)}
                              className="item">
                              <p className="name">{getText('message')}</p>
                            </div>
                            {currentChat.targetType === chatTypes.channel && checkCanRemoveMember(member) && (
                              <>
                                <hr className="horizontal-line" />
                                <div
                                  role="button"
                                  tabIndex={0}
                                  onClick={() => handleOpenRemoveMemberModal(member)}
                                  className="item">
                                  <p className="name">{getText('remove')}</p>
                                </div>
                              </>
                            )}
                          </ActionsContainer>
                        )}
                      </div>
                    )}
                  </div>
                ))}
              </div>
            </Collapse>

            {isAdmin && (
              <ConfirmationModal
                showModal={openModal.removeMember}
                onOk={handleRemoveMemberFromChat}
                onCancel={handleCloseModal}
                title={getText('removeMember')}>
                {getText('confirmRemoveMember', { member: getUserDisplayName(openModal.user ?? {}) })}
              </ConfirmationModal>
            )}
          </>
        )}
      </div>
    </div>
  );
};

ChatInfo.propTypes = {
  onClose: PropTypes.func,
};

export default ChatInfo;
