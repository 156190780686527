import { emitters } from 'enums/chat';
import { sendMessage } from './websocket';

// Create new channel
export const createChannel = (data, callback) => sendMessage(emitters.createChannel, data, callback);

// Update channel information, without members
export const updateChannel = (data, callback) => sendMessage(emitters.updateChannel, data, callback);

// Delete existing channel
export const deleteChannel = (id, callback) => sendMessage(emitters.deleteChannel, { id }, callback);

// Join a public channel
export const joinChannel = (id, callback) => sendMessage(emitters.joinChannel, { id }, callback);

// Leave a channel
export const leaveChannel = (id, callback) => sendMessage(emitters.leaveChannel, { id }, callback);

// Add users to channel
export const addUsersToChannel = (data, callback) => sendMessage(emitters.addUsersToChannel, data, callback);

// Remove users from channel
export const removeUsersFromChannel = (data, callback) => sendMessage(emitters.removeUsersFromChannel, data, callback);
