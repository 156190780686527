import { commonStyles } from 'styles';

export const container = (props) => [
  {
    '.step-container': {
      display: 'flex',
      justifyContent: 'space-between',
      width: 300,
    },

    ...commonStyles(props),
  },
];

export const stepItem = (isActive, isCompleted) => ({
  position: 'relative',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  width: 144,

  '&:not(:first-of-type)': {
    '&:before': {
      content: "''",
      backgroundColor: isActive || isCompleted ? 'var(--primary)' : 'var(--background-stronger)',
      position: 'absolute',
      width: '100%',
      right: '56%',
      top: '20%',
      transform: 'translateY(20%)',
      height: 3,
    },
  },

  '.step': {
    backgroundColor: isActive || isCompleted ? 'var(--primary)' : 'var(--background-stronger)',
    width: isActive || isCompleted ? 15 : 7,
    height: isActive || isCompleted ? 15 : 7,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    zIndex: 10,
    position: 'relative',
    borderRadius: '50%',
    fontWeight: 600,
    color: 'var(--theme-color)',

    '&:before': {
      content: "''",
      backgroundColor: isActive || isCompleted ? 'var(--gray-200)' : 'var(--gray-100)',
      width: 7,
      height: 7,
      borderRadius: '50%',
    },
  },

  '.text': {
    fontWeight: 500,
    color: isActive || isCompleted ? 'var(--gray-200)' : 'var(--border-color)',
    textTransform: 'lowercase',
  },
});
