import { forwardRef } from 'react';
import PropTypes from 'prop-types';
import { Dropdown, Icon, Row } from 'components';
import { usePagination } from './usePagination';
import { paginationContainer, paginationButton, pageSizeDropdown } from './styles';

export const Pagination = forwardRef((props, ref) => {
  const { pageSizes, withPageSize = false, justify } = props;
  const { totalResults, totalPages, pageIndex, pageSize, handlePageChange, handlePageSizeChange, defaultPageSizes } =
    usePagination({
      ...props,
      ref,
    });
  const noResults = totalPages === 0;
  const needEllipses = totalPages > 5;

  const arrowsButton = (disabled, type) => (
    <span
      css={paginationButton(disabled, false)}
      {...(!disabled && {
        onClick: () => handlePageChange(pageIndex - (type === 'left' ? 1 : -1)),
      })}>
      <Icon material size={28} iconName={type === 'left' ? 'chevron_left' : 'chevron_right'} />
    </span>
  );

  const pageButton = (ind) => (
    <span
      key={ind}
      css={paginationButton(totalPages === 1, ind === pageIndex)}
      {...(totalPages !== 1 &&
        pageIndex !== ind && {
          onClick: () => handlePageChange(ind),
        })}>
      {ind}
    </span>
  );

  const ellipsesElement = <span css={paginationButton(true, false)}>...</span>;

  const fromIndex = (pageIndex - 1) * pageSize + 1;
  const toIndex = pageIndex * pageSize;

  return (
    !!totalResults && (
      <Row justify={justify ?? (withPageSize ? 'space-between' : 'left')}>
        {withPageSize && (
          <Dropdown
            noClear
            disabled={totalResults < 1}
            options={pageSizes ?? defaultPageSizes}
            value={pageSize}
            onChange={handlePageSizeChange}
            className={pageSizeDropdown}
          />
        )}

        <div css={paginationContainer}>
          <div className="shown-results-indicator">
            {`${fromIndex} - ${toIndex > totalResults ? totalResults : toIndex} `}({totalResults})
          </div>
          {arrowsButton(pageIndex === 1 || noResults, 'left')}
          {needEllipses ? (
            <>
              {pageButton(1)}
              {pageIndex > 3 && ellipsesElement}
              {pageIndex > 2 && pageButton(pageIndex - 1)}
              {pageIndex !== 1 && pageIndex !== totalPages && pageButton(pageIndex)}
              {pageIndex < totalPages - 1 && pageButton(pageIndex + 1)}
              {totalPages - pageIndex > 2 && ellipsesElement}
              {pageButton(totalPages)}
            </>
          ) : (
            Array(totalPages)
              .fill()
              .map((_, ind) => pageButton(ind + 1))
          )}
          {arrowsButton(pageIndex === totalPages || noResults, 'right')}
        </div>
      </Row>
    )
  );
});

Pagination.propTypes = {
  onChange: PropTypes.func,
  pageSizes: PropTypes.array,
  withPageSize: PropTypes.bool,
  pageIndex: PropTypes.number,
  totalResults: PropTypes.number,
  pageSize: PropTypes.number,
  noInitialCall: PropTypes.bool,
  justify: PropTypes.string,
  noFetchOnMount: PropTypes.bool,
  filterKeys: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  requiredFilterKeys: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
};
