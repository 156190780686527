import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslations } from '@veraio/strank';
import { isEmpty } from '@veraio/core';
import { CheckButton } from 'components';
import { orderUsersByLetter } from 'utils';
import { contactsList } from './styles';

const ContactsList = (props) => {
  const { contacts, checked, onSelect } = props;
  const { getText } = useTranslations();
  const [contactsByLetter, setContactsByLetter] = useState({});

  useEffect(() => {
    getContactsByLetter();
  }, [contacts]);

  const getContactsByLetter = async () => {
    const orderedContacts = orderUsersByLetter(contacts);
    setContactsByLetter(orderedContacts);
  };

  const handleSelect = (isChecked, contactId) => onSelect({ isChecked, contactId });

  return (
    <div css={contactsList}>
      <div className="contacts-title">{getText('contacts')}</div>
      <div className="contacts-body">
        {!isEmpty(contactsByLetter) ? (
          Object.keys(contactsByLetter).map((key) => (
            <div key={`letter-${key}`}>
              <span>``</span>
              <div className="letter">{key}</div>
              <ul className="contacts-list">
                {contactsByLetter[key].map((contact) => (
                  <li key={`contact-${contact.id}`} className="contact">
                    <CheckButton
                      className="check-contact"
                      onChange={(value) => handleSelect(value, contact.id)}
                      value={checked?.includes(contact.id)}>
                      {contact.name}
                    </CheckButton>
                  </li>
                ))}
              </ul>
            </div>
          ))
        ) : (
          <div className="no-data-text">{getText('noContacts')}</div>
        )}
      </div>
    </div>
  );
};

ContactsList.propTypes = {
  contacts: PropTypes.array,
  checked: PropTypes.array,
  onSelect: PropTypes.func,
};

export default ContactsList;
