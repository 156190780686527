export const createChannelContainer = {
  '.channel-modal-container': {
    backgroundColor: 'var(--background-stronger)',
    '.modal-title': {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      padding: '0 1.5rem 2rem 1.5rem',
      borderBottom: '1px solid var(--border-color)',
      color: 'var(--gray-200)',
    },

    '.modal-body': {
      padding: '2.75rem 2.5rem 1rem 2.5rem',
      '.channel-image-selector': {
        display: 'flex',
        alignItems: 'center',
        marginBottom: '1.5rem',

        '.upload-button': {
          '.button': {
            maxHeight: '3.8rem',
            color: 'var(--gray-200)',
          },
        },
        '.image-preview': {
          marginRight: '6rem',
          position: 'relative',
          '.avatar-image': {
            minWidth: 70,
            maxWidth: 70,
            minHeight: 70,
            maxHeight: 70,
          },
          '.remove-button': {
            position: 'absolute',
            bottom: 0,
            right: 0,
            backgroundColor: 'var(--gray-800)',
            borderRadius: '50%',
            padding: 2,
            border: '1px solid var(--error)',
            color: 'var(--error)',
          },
          '.letter-avatar': {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            backgroundColor: 'var(--primary-weaker)',
            color: 'var(--primary)',
            borderRadius: '50%',
            width: 70,
            height: 70,
            padding: 5,
            fontSize: 28,
            fontWeight: 500,
          },
        },
      },

      '.members-container': {
        display: 'flex',
        flexDirection: 'column',
        marginBottom: '2rem',
        marginTop: '1rem',
        fontWeight: 500,

        label: {
          color: 'var(--secondary)',
        },

        p: {
          fontSize: 10,
        },

        '.members-button': {
          fontSize: 13,
          width: '12rem',
          minHeight: '3.2rem',
          margin: '1rem 0',
        },

        '.contacts-container': {
          marginTop: '1rem',
          backgroundColor: 'var(--theme-color)',
          border: '1px solid var(--border-color-weak)',

          '.contacts-title': {
            fontWeight: 600,
            backgroundColor: 'var(--background-strong)',
            padding: '1rem 1.5rem',
          },

          '.contacts-body': {
            maxHeight: '160px',
            overflow: 'auto',
            padding: '1rem',
            color: 'var(--secondary)',

            '.letter': {
              color: 'var(--primary)',
              fontSize: 16,
              fontWeight: 500,
              margin: '0.5em 0',
              padding: '1.2rem',
            },

            '.members-button': {
              fontSize: 13,
              width: '12rem',
              minHeight: '3.2rem',
              margin: '1rem 0',
            },
          },
        },
      },

      '.checkbox-container': {
        display: 'flex',
        padding: '1rem',
      },
    },

    '.action-container': {
      display: 'flex',
      justifyContent: 'flex-end',
      padding: '2rem 1.5rem 0 1.5rem',
      borderTop: '1px solid var(--border-color)',
      '.close-button': {
        marginRight: 12,
        '&:hover': {
          textDecoration: 'underline',
        },
      },
    },
  },
};

export const iconFileInput = {
  padding: 0,
  '.button': {
    padding: '1rem',
    backgroundColor: 'var(--gray-400)',
  },
};
