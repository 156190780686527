import PropTypes from 'prop-types';
import { ColorBatch } from 'components/ui';
import { badge, groupListItem } from './styles';
import { useMessagesStore } from 'stores';

const GroupListItem = (props) => {
  const { group, isSelected, onChatOpen } = props;
  const { messages } = useMessagesStore();

  const handleOpenChat = () => onChatOpen(group.id);

  const getUnreadMessagesText = (count) => {
    if (count >= 1 && count <= 9) return `0${count}`;
    else if (count > 99) return '99+';

    return count;
  };

  const getFirstSymbol = (val) => {
    // Check if name starts with emoji
    const firstSymbolEmojiRegex = /^\p{RGI_Emoji}/gv;
    if (firstSymbolEmojiRegex.test(val)) return val.match(firstSymbolEmojiRegex)[0] ?? 'G';
    return val[0]?.toUpperCase();
  };

  return (
    <li role="presentation" css={groupListItem(isSelected)} onClick={handleOpenChat}>
      <div className="group-info">
        <div className="letter-avatar">
          <div>{getFirstSymbol(group.name)}</div>
        </div>

        <div className="group-name">{group.name}</div>
      </div>

      <div className="group-tags">
        {messages[group.id]?.unreadCount > 0 && (
          <ColorBatch withoutStartCase type="error" css={badge}>
            {getUnreadMessagesText(messages[group.id].unreadCount)}
          </ColorBatch>
        )}
      </div>
    </li>
  );
};

GroupListItem.propTypes = {
  group: PropTypes.object,
  isSelected: PropTypes.bool,
  onChatOpen: PropTypes.func,
};

export default GroupListItem;
