import PropTypes from 'prop-types';
import { isFunction, lowerCase } from 'lodash-es';
import { useHistory } from 'react-router-dom';
import { useTranslations } from '@veraio/strank';
import { Icon } from 'components';
import { backButtonContainer } from './styles';

const BackButton = ({ className, onBack }) => {
  const history = useHistory();
  const { getText } = useTranslations();

  return (
    <div
      role="button"
      tabIndex={0}
      onClick={onBack && isFunction(onBack) ? onBack : history.goBack}
      css={backButtonContainer}
      {...(className && { className })}>
      <Icon material iconName="arrow_back" size={20} color="primary" className="icon" />
      <p className="text">{lowerCase(getText('back'))}</p>
    </div>
  );
};

BackButton.propTypes = {
  className: PropTypes.string,
  onBack: PropTypes.func,
};

export default BackButton;
