import { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { isFunction } from 'lodash-es';
import { Icon } from '../Icon';
import { Timer } from '../utils';
import { notification, notifContainer, notificationIcon } from './styles';

const getIcon = {
  success: 'check_circle',
  error: 'error',
  warning: 'warning',
  info: 'info',
};

const Notification = (props) => {
  const {
    id,
    onRemoval,
    title,
    message,
    content,
    duration = 7000,
    toggleRemoval,
    onClick,
    type,
    iconName,
    alwaysShow = false,
    pauseOnHover = true,
    className,
  } = props;
  const timerRef = useRef();
  const callbackRef = useRef(onRemoval);
  const [hide, setHide] = useState(false);

  const onTransitionStart = () => {
    if (!duration || alwaysShow) return;
    const notifTimer = new Timer(() => setHide(true), duration);
    timerRef.current = notifTimer;
    notifTimer.start();
  };

  useEffect(() => {
    pauseOnHover && onTransitionStart();

    return () => {
      pauseOnHover && timerRef.current?.clear();
    };
  }, []);

  const onTransitionEnd = () => {
    toggleRemoval(id);
    isFunction(callbackRef) && callbackRef(props);
  };

  const onMouseEnter = () => timerRef.current?.pause();

  const onMouseLeave = () => timerRef.current?.start();

  const handleClick = () => {
    callbackRef.current = isFunction(onClick) ? onClick : onRemoval;
    setHide(true);
  };

  return (
    <div
      role="presentation"
      css={notification(type, hide, props)}
      {...(className && { className })}
      onMouseEnter={pauseOnHover ? onMouseEnter : null}
      onMouseLeave={pauseOnHover ? onMouseLeave : null}
      onClick={handleClick}
      onTransitionEnd={hide ? onTransitionEnd : onTransitionStart}>
      {content ?? (
        <div css={notifContainer(type)}>
          {iconName ? (
            <Icon material iconName={iconName} size={18} color="white" />
          ) : (
            <Icon material iconName={`${getIcon[type]}`} size={18} color="white" css={notificationIcon} />
          )}
          <span className="notification-text">
            {title && <h4>{title}</h4>}
            {message && <span>{message}</span>}
          </span>
        </div>
      )}
    </div>
  );
};

Notification.propTypes = {
  id: PropTypes.string,
  onRemoval: PropTypes.func,
  title: PropTypes.string,
  message: PropTypes.string,
  content: PropTypes.node,
  duration: PropTypes.number,
  type: PropTypes.string,
  toggleRemoval: PropTypes.func,
  onClick: PropTypes.func,
  alwaysShow: PropTypes.bool,
  iconName: PropTypes.string,
  pauseOnHover: PropTypes.bool,
  className: PropTypes.string,
};

export default Notification;
