import PropTypes from 'prop-types';
import { useTranslations } from '@veraio/strank';
import { Button, ContactsList, Form, Icon, Modal } from 'components';
import { addMembersModal } from './styles';
import { userBySub } from 'services/mocks';
import { useChatStore } from 'stores';

const AddMembersModal = (props) => {
  const { showModal, onClose, onAdd } = props;
  const { currentChat } = useChatStore();
  const { getText } = useTranslations();

  const handleSubmit = (formData, resetForm) => {
    if (!formData.users?.length) return;
    onAdd(formData.users, resetForm);
  };

  const handleClose = () => {
    onClose();
  };

  const handleSelectMember = ({ isChecked, contactId }, setValues) => {
    setValues((prev) => ({
      ...prev,
      users: isChecked ? [...(prev.users ?? []), contactId] : (prev.users ?? []).filter((id) => id !== contactId) ?? [],
    }));
  };

  return (
    <Modal show={showModal} onClose={onClose} css={addMembersModal}>
      <Form className="members-modal-container" values={currentChat?.users} onSubmit={handleSubmit}>
        <div className="modal-title">
          <h4>{getText('addMembers')}</h4>
          <Icon iconName="close" size={16} onClick={handleClose} />
        </div>

        <div className="modal-body">
          {({ values, setValues }) => {
            const { users } = values;
            return (
              <div className="members-container">
                <label>{`${getText('selectedMembersToAdd')}: ${users?.length ?? 0}`}</label>
                <ContactsList
                  checked={users}
                  contacts={Object.values(userBySub).filter(
                    (mockUser) => !currentChat?.users?.find((user) => user.email === mockUser.email),
                  )}
                  onSelect={(selected) => handleSelectMember(selected, setValues)}
                />
              </div>
            );
          }}
        </div>

        <div className="action-container">
          {({ onSubmit }) => (
            <>
              <Button small type="secondary" className="close-button" onClick={handleClose}>
                {getText('close')}
              </Button>
              <Button small onClick={onSubmit}>
                {getText('addMembers')}
              </Button>
            </>
          )}
        </div>
      </Form>
    </Modal>
  );
};

AddMembersModal.propTypes = {
  showModal: PropTypes.bool,
  onAdd: PropTypes.func,
  onClose: PropTypes.func,
};

export default AddMembersModal;
