// import moment from 'moment';

export const userInfo = (user) => [
  {
    label: 'name',
    value: `${user?.firstName} ${user?.lastName}`,
  },
  {
    label: 'username',
    value: user.userName,
  },
  // {
  //   label: 'time',
  //   value: moment().format('LT'),
  // },
  {
    label: 'country',
    value: user?.country,
  },
];

export const attachedFiles = [
  {
    id: 1,
    icon: 'feed',
    title: 'Admin-A.zip',
    size: '12.5 MB',
  },
  {
    id: 2,
    icon: 'wallpaper',
    title: 'Image-1.jpg',
    size: '4.2 MB',
  },
  {
    id: 3,
    icon: 'wallpaper',
    title: 'Image-2.jpg',
    size: '3.1 MB',
  },
  {
    id: 4,
    icon: 'feed',
    title: 'Landing-A.zip',
    size: '6.7 MB',
  },
  {
    id: 5,
    icon: 'wallpaper',
    title: 'Image-2.jpg',
    size: '3.1 MB',
  },
  {
    id: 6,
    icon: 'feed',
    title: 'Landing-A.zip',
    size: '6.7 MB',
  },
];
