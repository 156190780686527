import PropTypes from 'prop-types';
import { useTranslations } from '@veraio/strank';
import { Avatar, TextWithDotsAnimation } from 'components/shared';
import { ColorBatch, Icon } from 'components/ui';
import { displayTime, parseUuid7ToDate } from 'utils';
import { badge, chatListItem } from './styles';
import { chatTypes } from 'enums';
import { useMessagesStore } from 'stores';

const ConversationListItem = (props) => {
  const { chat, isSelected, onChatOpen } = props;
  const { messages } = useMessagesStore();
  const { getText } = useTranslations();

  const lastMessage = messages[chat.id]?.messages[0];

  const handleOpenChat = () => onChatOpen(chat.id, chat.targetType);

  const getUnreadMessagesText = (count) => {
    if (count >= 1 && count <= 9) return `0${count}`;
    else if (count > 99) return '99+';

    return count;
  };

  const getFirstSymbol = (val) => {
    // Check if name starts with emoji
    const firstSymbolEmojiRegex = /^\p{RGI_Emoji}/gv;
    if (firstSymbolEmojiRegex.test(val))
      return val.match(firstSymbolEmojiRegex)[0] ?? chat.targetType[0]?.toUpperCase();

    return val[0]?.toUpperCase();
  };

  const getUserDisplayName = (userData) => {
    let displayName = userData.first_name;

    if (userData.last_name) displayName = `${displayName && displayName + ' '}${userData.last_name}`;

    return displayName || userData.username;
  };

  return (
    <li key={chat.id} role="presentation" css={chatListItem(isSelected)} onClick={handleOpenChat}>
      <div className="chat-info">
        {chat.targetType === chatTypes.group || (chat.targetType === chatTypes.channel && !chat.icon) ? (
          <div className="letter-avatar">
            <div>{getFirstSymbol(chat.name)}</div>
          </div>
        ) : chat.targetType === chatTypes.channel ? (
          <Avatar picThumbnailUrl={chat.icon} />
        ) : (
          <Avatar picThumbnailUrl={chat.user?.pictureUrl} status={chat.user?.status} />
        )}

        <div className="chat-data">
          <div className="sender-name">{chat.name ?? getUserDisplayName(chat.user)}</div>
          <div className="last-message-container">
            {chat.isTyping ? (
              <TextWithDotsAnimation text={getText('typing')} />
            ) : (
              <>
                {lastMessage?.image && <Icon className="image-message-icon" iconName="imagesmode" size={18} />}
                {lastMessage?.file && <Icon className="image-message-icon" iconName="attach_file" size={18} />}
                <p>{lastMessage?.content}</p>
              </>
            )}
          </div>
        </div>
      </div>

      <div className="chat-time">
        <div className="last-message-date">
          {lastMessage?.messageId && displayTime(parseUuid7ToDate(lastMessage.messageId))}
        </div>
        {messages[chat.id]?.unreadCount > 0 && (
          <ColorBatch withoutStartCase type="error" css={badge}>
            {getUnreadMessagesText(messages[chat.id].unreadCount)}
          </ColorBatch>
        )}
      </div>
    </li>
  );
};

ConversationListItem.propTypes = {
  chat: PropTypes.object,
  isSelected: PropTypes.bool,
  onChatOpen: PropTypes.func,
};

export default ConversationListItem;
