export const inputField = {
  display: 'none',
};

export const uploadWrap = (hasValidation, { multiple, disabled, small }) => [
  {
    color: 'var(--secondary)',
    borderRadius: '0.5rem',
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    flexDirection: 'column',
    marginBottom: hasValidation ? 6 : 0,
    minHeight: small ? '3.2rem' : '4.4rem',
    background: 'transparent',
    border: multiple ? 'none' : '1px solid var(--gray-400)',

    '& img': {
      maxWidth: '100%',
      height: 'auto',
      maxHeight: '4.4rem',
    },

    '.button': {
      color: 'var(--info-dark)',
      backgroundColor: 'var(--gray-400)',
      fontSize: 13,
      padding: '0.85rem',
      ':hover': {
        backgroundColor: 'var(--gray-400)'
      }
    },
  },
  disabled && {
    borderColor: 'var(--background-weak)',
    color: 'var(--gray-500)',
    cursor: 'not-allowed',
  },
];

export const itemImageWrap = {
  backgroundColor: 'var(--theme-color)',
  padding: '0.4em',
  borderRadius: 6,
};

export const fileInputContent = ({ multiple }) => ({
  width: '100%',
  padding: multiple ? '0.8em 1em' : 0,
  borderRadius: multiple ? 6 : 0,
  background: 'transparent',
  border: multiple ? '1px solid var(--border-color)' : 'none',
  flex: 1,
  display: 'flex',
  alignItems: 'center',
  marginTop: multiple ? 8 : 0,
  flexDirection: 'row-reverse',
});

export const fileInputLabel = ({ small }) => ({
  display: 'flex',
  justifyContent: 'center',
  flex: 1,
  padding: small ? '0.35em 0.85em' : '0.7em 0.85em',
  textOverflow: 'ellipsis',
});
