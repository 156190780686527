import { useState } from 'react';
import { capitalize } from 'lodash-es';
import { useTranslations } from '@veraio/strank';
import { ActionsContainer, Collapse, Icon, Image } from 'components';
import { useUserStore } from 'stores';
import avatarLogo from 'assets/images/avatar.svg';
import { attachedFiles, userInfo } from './config';
import { myProfileContainer } from './styles';
import { userChatStatusMap } from 'enums';

const MyProfile = () => {
  const { getText } = useTranslations();
  const user = useUserStore();
  const [openContainer, setOpenContainer] = useState({ userActions: false, fileActions: false });
  const userMotto =
    'If several languages coalesce, the grammar of the resulting language is more simple and regular than that of the individual.';

  const hideContainer = () => setOpenContainer({ userActions: false, fileActions: false });

  const handleDownloadFile = (url) => {
    const a = document.createElement('a');
    a.href = url;
    a.download = url.split('/').pop();
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  };

  return (
    <div css={myProfileContainer(userChatStatusMap[user.status]?.color)}>
      <div className="page-title">
        <h4>{getText('myProfile')}</h4>
        <Icon
          iconName="more_vert"
          color="secondary"
          onClick={() => setOpenContainer((prev) => ({ ...prev, userActions: !prev.userActions }))}
        />
      </div>

      {openContainer.userActions && (
        <ActionsContainer styles={{ top: 56, right: 10, width: 170 }} onBlur={hideContainer}>
          <div role="button" tabIndex={0} onClick={() => console.log('edit')} className="item">
            <p className="name">{getText('edit')}</p>
          </div>
          <div role="button" tabIndex={0} onClick={() => console.log('action')} className="item">
            <p className="name">{getText('action')}</p>
          </div>
          <hr className="horizontal-line" />
          <div role="button" tabIndex={0} onClick={() => console.log('another action')} className="item">
            <p className="name">{getText('anotherAction')}</p>
          </div>
        </ActionsContainer>
      )}

      <div className="user-container">
        <Image src={user?.pictureUrl ?? user?.picThumbnailUrl ?? avatarLogo} className="avatar-image" />
        <h5 className="name">{`${user?.firstName} ${user?.lastName}`}</h5>
        <div className="status-container">
          <div className="status-indicator" />
          <p className="status">{user?.status}</p>
        </div>
      </div>

      <hr className="horizontal-line" />

      <div className="profile-description">
        {userMotto && <p className="motto">{capitalize(getText(userMotto))}</p>}
        <Collapse header={getText('about')} cardIconName="person">
          <div className="user-content-container">
            {userInfo(user)?.map((el, ind) => (
              <div key={ind} className="user-content">
                <p className="label">{getText(el.label)}</p>
                <h5 className="name">{el.value}</h5>
              </div>
            ))}
          </div>
        </Collapse>

        <Collapse header={getText('attachedFiles')} cardIconName="attach_file">
          <div className="attached-container">
            {attachedFiles?.map((el, ind) => (
              <div key={ind} className="file-content">
                <div className="file-info-container">
                  <div className="file-icon-container">
                    <Icon iconName={el.icon} color="primary" size={24} />
                  </div>
                  <div>
                    <h5 className="name">{getText(el.title)}</h5>
                    <p className="size">{el.size}</p>
                  </div>
                </div>
                <div>
                  <Icon
                    iconName="download"
                    color="secondary-weaker"
                    size={24}
                    className="icon"
                    onClick={() => handleDownloadFile('/home')}
                  />
                  <Icon
                    iconName="more_horiz"
                    color="secondary-weaker"
                    size={24}
                    onClick={() => setOpenContainer((prev) => ({ ...prev, fileActions: true, openElement: el.id }))}
                  />
                </div>
                {openContainer.fileActions && openContainer.openElement === el.id && (
                  <ActionsContainer
                    styles={{ top: 36, right: 10, width: 170, background: 'theme-color', color: 'secondary-weaker' }}
                    onBlur={hideContainer}>
                    <div role="button" tabIndex={0} onClick={() => console.log('edit')} className="item">
                      <p className="name">{getText('action')}</p>
                    </div>
                    <div role="button" tabIndex={0} onClick={() => console.log('action')} className="item">
                      <p className="name">{getText('anotherAction')}</p>
                    </div>
                    <hr className="horizontal-line" />
                    <div role="button" tabIndex={0} onClick={() => console.log('another action')} className="item">
                      <p className="name">{getText('delete')}</p>
                    </div>
                  </ActionsContainer>
                )}
              </div>
            ))}
          </div>
        </Collapse>
      </div>
    </div>
  );
};

export default MyProfile;
