export const chatTypes = {
  group: 'group',
  chat: 'chat',
  channel: 'channel',
};

export const listeners = {
  init: 'init',
  catchup: 'catchup',
  channelCreated: 'channelCreated',
  channelUpdated: 'channelUpdated',
  channelDeleted: 'channelDeleted',
  channelJoined: 'channelJoined',
  channelLeft: 'channelLeft',
  userAddedToChannel: 'userAddedToChannel',
  usersAddedToChannel: 'usersAddedToChannel',
  usersRemovedFromChannel: 'usersRemovedFromChannel',
  usersAddedToGroup: 'usersAddedToGroup',
  userAddedToGroup: 'userAddedToGroup',
  usersRemovedFromGroup: 'usersRemovedFromGroup', // do we need it?
  groupCreated: 'groupCreated',
  groupDeleted: 'groupDeleted',
  groupLeft: 'groupLeft',
  chatCreated: 'chatCreated',
  messageSent: 'messageSent',
  presenceUpdated: 'presenceUpdated',
};

export const emitters = {
  createChannel: 'createChannel',
  updateChannel: 'updateChannel',
  deleteChannel: 'deleteChannel',
  joinChannel: 'joinChannel',
  leaveChannel: 'leaveChannel',
  addUsersToChannel: 'addUsersToChannel',
  removeUsersFromChannel: 'removeUsersFromChannel',
  addUsersToGroup: 'addUsersToGroup',
  removeUsersFromGroup: 'removeUsersFromGroup',
  createGroup: 'createGroup',
  deleteGroup: 'deleteGroup',
  leaveGroup: 'leaveGroup',
  createChat: 'createChat',
  sendMessage: 'sendMessage',
  search: 'search',
  updatePresence: 'updatePresence',
};