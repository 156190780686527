import { useEffect, useState } from 'react';
import { useTranslations } from '@veraio/strank';
import { Avatar, SearchInput, ItemsSlider } from 'components/shared';
import { ConversationListItem } from 'components/screens';
import { setCurrentChat, useChatStore, useUserStore, resetUnreadMessages, useMessagesStore } from 'stores';
import { chatTypes } from 'enums/chat';
import { getTimestampFromUuidv7 } from 'utils';
import { createDirectChat } from 'services';
import { chatsContainer } from './styles';

const Chats = () => {
  const { getText } = useTranslations();
  const { chats, groups, channels, currentChat } = useChatStore();
  const { messages } = useMessagesStore();
  const user = useUserStore();
  const [recentUsers, setRecentUsers] = useState([]);
  const [recentChats, setRecentChats] = useState([]);
  const [openedChatId, setOpenedChatId] = useState(currentChat?.id);
  const [searchText, setSearchText] = useState();

  useEffect(() => {
    getRecentUsers();
    getRecentChats();
  }, [chats, groups, channels, user, messages]);

  useEffect(() => {
    setOpenedChatId(currentChat?.id);
  }, [currentChat?.id]);

  const handleSearch = (val) => {
    setSearchText(val);
    getRecentChats({ search: val });
  };

  const filterChatsByTextAndMessages = (text) => {
    const allChats = [...(chats ?? []), ...(groups ?? []), ...(channels ?? [])];

    return text
      ? allChats.filter(
          (el) =>
            !!messages[el.id]?.messages?.length &&
            (el.user
              ? el.user.email?.toLowerCase() === text.toLowerCase() ||
                el.user.first_name?.toLowerCase()?.includes(text.toLowerCase()) ||
                el.user.last_name?.toLowerCase()?.includes(text.toLowerCase())
              : el.name && el.name.toLowerCase().includes(text.toLowerCase())),
        )
      : allChats.filter((el) => !!messages[el.id]?.messages?.length);
  };

  const sortChatsByLastMessage = (chatsArray) => {
    return chatsArray.sort((chatA, chatB) => {
      // Chats should be filtered by messages so every chat should have at least one message here
      const chatALastMessage = messages[chatA.id]?.messages[0] ?? {};
      const chatBLastMessage = messages[chatB.id]?.messages[0] ?? {};

      const timeA = chatALastMessage.messageId && getTimestampFromUuidv7(chatALastMessage.messageId);
      const timeB = chatBLastMessage.messageId && getTimestampFromUuidv7(chatBLastMessage.messageId);

      return timeB - timeA;
    });
  };

  const getRecentUsers = () => {
    const users = chats.map((chat) => chat.user);

    setRecentUsers(users);
  };

  const getRecentChats = (options) => {
    const search = options?.search ?? searchText;
    const filteredChats = filterChatsByTextAndMessages(search);
    const sortedChats = sortChatsByLastMessage(filteredChats);

    setRecentChats(sortedChats);
  };

  const handleOpenChat = (id, targetType) => {
    setCurrentChat(id, targetType);
    resetUnreadMessages(id);
  };

  const handleOpenChatWithUser = (userId) => {
    const foundChat = chats?.find((chat) => chat.user.id === userId);

    if (!foundChat) {
      setCurrentChat(userId, chatTypes.chat);
      createDirectChat({ user: userId });
    } else {
      setCurrentChat(foundChat.id, chatTypes.chat);
      resetUnreadMessages(foundChat.id);
    }
  };

  const getUserDisplayName = (userData) => {
    let displayName = userData.first_name;

    if (userData.last_name) displayName = `${displayName && displayName + ' '}${userData.last_name}`;

    return displayName || userData.username;
  };

  return (
    <div css={{ display: 'flex' }}>
      <div css={chatsContainer}>
        <div className="page-title">
          <h4>{getText('chats')}</h4>
        </div>

        <SearchInput placeholder={getText('searchChats')} onChange={handleSearch} />

        <div className="recent-users-container">
          <ItemsSlider>
            {recentUsers?.map((rUser) => (
              <div
                key={rUser.id}
                className="user-status-box"
                role="presentation"
                onClick={() => handleOpenChatWithUser(rUser.id)}>
                <div className="avatar-status">
                  <Avatar {...rUser} />
                </div>
                <p className="recent-user-name">{getUserDisplayName(rUser)}</p>
              </div>
            ))}
          </ItemsSlider>
        </div>

        <div className="conversations-container">
          {recentChats && recentChats.length ? (
            <>
              <h5>{getText('recent')}</h5>
              <ul>
                {recentChats?.map((chat) => (
                  <ConversationListItem
                    key={chat.id}
                    chat={chat}
                    isSelected={openedChatId === chat.id}
                    onChatOpen={handleOpenChat}
                  />
                ))}
              </ul>
            </>
          ) : (
            <div className="no-data">{getText('noChatsFound')}</div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Chats;
