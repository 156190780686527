import { startCase, isNumber, isArray, flatten } from 'lodash-es';

const parseTitle = (title) => (isNumber(Number(title)) ? 'Error' : title);

const parseFormDataError = (err) => ({
  title: `Invalid ${Object.keys(err.errors).map(startCase).join(', ')}`,
});

const parseSingleTitleAndMessage = (err) => ({
  title: parseTitle(err?.code),
  message: err?.response?.data?.detail,
});

const parseMultiTitleAndMessage = (res) => {
  const result = flatten(
    res.map((el) => {
      const message = el?.message ?? '';
      const jsonStart = message.indexOf('{') === -1 ? null : message.indexOf('{');
      const jsonMessage = message.substr(jsonStart ?? message.length);
      if (!jsonMessage?.length) return parseSingleTitleAndMessage(el);

      try {
        const parsedMessage = JSON.parse(jsonMessage);
        if (!parsedMessage?.errors?.length) return [];
        return parsedMessage?.errors.map((err) => ({
          title: parseTitle(err?.code),
          message: err?.description,
        }));
      } catch (err) {
        return parseSingleTitleAndMessage(el);
      }
    }),
  );
  return result;
};

export const parseError = (error) =>
  isArray(error)
    ? parseMultiTitleAndMessage(error)
    : error?.errors
    ? [parseFormDataError(error)]
    : [parseSingleTitleAndMessage(error)];
