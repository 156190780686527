import { Icon } from 'components/ui';
import PropTypes from 'prop-types';
import { sliderContainer } from './styles';
import { useRef } from 'react';

const ItemsSlider = (props) => {
  const { children, iconLeftProps, iconRightProps } = props;
  const sliderRef = useRef();

  const handleScroll = (direction) => {
    direction === 'left' ? sliderRef && (sliderRef.current.scrollLeft -= 200) : sliderRef && (sliderRef.current.scrollLeft += 200);
  };

  return (
    <div css={sliderContainer(sliderRef?.current?.scrollWidth >= sliderRef?.current?.offsetWidth)}>
      <Icon
        iconName="chevron_left"
        className="scroll-icon-left"
        onClick={() => handleScroll('left')}
        size={32}
        {...(iconLeftProps ?? {})}
      />

      <div className="scrollable-container" ref={sliderRef}>
        {children}
      </div>

      <Icon
        iconName="chevron_right"
        className="scroll-icon-right"
        onClick={() => handleScroll('right')}
        size={32}
        {...(iconRightProps ?? {})}
      />
    </div>
  );
};

ItemsSlider.propTypes = {
  children: PropTypes.any,
  ref: PropTypes.object,
  iconLeftProps: PropTypes.object,
  iconRightProps: PropTypes.object,
};

export default ItemsSlider;
