import { useEffect, useState } from 'react';
import { useTranslations } from '@veraio/strank';
import { CreateGroupModal, GroupListItem, Icon, SearchInput, showSuccess } from 'components';
import { groupsContainer } from './styles';
import { createGroup } from 'services';
import { setCurrentChat, useChatStore } from 'stores';
import { chatTypes } from 'enums/chat';
import { resetUnreadMessages } from 'stores/messages';

const Groups = () => {
  const { getText } = useTranslations();
  const { groups, currentChat } = useChatStore();
  const [openGroupModal, setOpenGroupModal] = useState(false);
  const [openedChatId, setOpenedChatId] = useState(currentChat?.id);
  const [myGroups, setMyGroups] = useState([]);
  const [searchText, setSearchText] = useState();

  useEffect(() => {
    getMyGroups();
  }, [groups]);

  useEffect(() => {
    setOpenedChatId(currentChat?.id);
  }, [currentChat?.id]);

  const getMyGroups = (options) => {
    const search = options?.search ?? searchText;

    search
      ? setMyGroups(groups.filter((group) => group.name.toLowerCase().includes(search.toLowerCase())))
      : setMyGroups(groups);
  };

  const handleSearch = (val) => {
    setSearchText(val);
    getMyGroups({ search: val });
  };

  const handleOpenChat = (id) => {
    setCurrentChat(id, chatTypes.group);
    resetUnreadMessages(id);
  };

  const handleOpenGroupModal = () => setOpenGroupModal(true);

  const handleCloseGroupModal = () => setOpenGroupModal(false);

  const handleCreateGroup = (data, resetForm) => {
    createGroup(data, (response) => {
      resetForm(!response.success);

      showSuccess('Successfully created a group');
      handleCloseGroupModal();
    });
  };

  return (
    <>
      <div css={groupsContainer}>
        <div className="page-title">
          <h4>{getText('groups')}</h4>
          <Icon iconName="group_add" color="secondary" onClick={handleOpenGroupModal} />
        </div>

        <SearchInput placeholder={getText('searchGroups')} onChange={handleSearch} />

        <div className="groups-container">
          {myGroups && myGroups.length ? (
            <ul>
              {myGroups?.map((group) => (
                <GroupListItem
                  key={group.id}
                  group={group}
                  isSelected={openedChatId === group.id}
                  onChatOpen={handleOpenChat}
                />
              ))}
            </ul>
          ) : (
            <div className="no-data">{getText('noGroupsFound')}</div>
          )}
        </div>
      </div>

      <CreateGroupModal showModal={openGroupModal} onCreate={handleCreateGroup} onClose={handleCloseGroupModal} />
    </>
  );
};

export default Groups;
