const MENU_SMALL_WIDTH = 76;

export const mainContainer = (styles) => [
  {
    width: '100vw',
    height: '100vh',
    display: 'flex',
    maxWidth: '457px',
    minWidth: '457px',
    flexDirection: 'row',
    overflow: 'hidden',

    '@media (max-width: 991px)': {
      flexDirection: 'column-reverse',
    },
  },
  styles,
];

export const sideNavContainer = {
  position: 'relative',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  width: MENU_SMALL_WIDTH,
  flexShrink: 0,
  transition: 'width .3s ease-in-out',

  '@media (max-width: 991px)': {
    width: '100%',
  },
};

export const menuListContainer = {
  overflow: 'hidden auto',
  height: '100%',
  padding: '0.6em 0 1.2em 0',
  position: 'relative',
  boxShadow: '0px 1px 4px rgba(0, 0, 0, 0.25)',
  transition: 'width .3s ease-in-out',
  zIndex: 1000,
  width: '100%',
  backgroundColor: 'var(--background)',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'space-between',

  '.logo-navbar': {
    margin: 12,
  },

  '@media (max-width: 991px)': {
    height: MENU_SMALL_WIDTH,
    flexDirection: 'row',
    justifyContent: 'stretch',
    width: '100%',

    '.middle-container': {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-around',
      flexBasis: '70%',
    },

    '.bottom-container': {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-around',
      flexBasis: '30%',
    },
  },
};

export const subMenuList = {
  backgroundColor: 'var(--gray-400)',
  color: 'var(--secondary)',
  borderRadius: 4,
  minWidth: 120,
};

export const subMenusTreeContainer = {
  padding: '0 1rem 0 3.2rem',
};

export const contentContainer = {
  flex: 1,
  display: 'flex',
  flexDirection: 'row',
  overflow: 'auto hidden',
};

export const sideNavContent = {
  flex: 1,
  overflow: 'auto',
};

export const tooltipContainer = {
  display: 'block',
};

export const tooltipContent = {
  '& .tooltip-content': {
    padding: '0.6em 1.4em',
    backgroundColor: 'var(--gray-200)',
    color: 'var(--theme-color)',
    boxShadow: 'none',
    marginLeft: 4,
    marginBottom: 20,
    borderRadius: 4,
    fontSize: 12,
  },
};

export const menuItem = (hideOnSmallScreen) => ({
  color: 'var(--secondary-weaker)',
  backgroundColor: 'transparent',
  padding: '0.8em 0.8em',
  margin: '0.6rem 1.2rem',
  position: 'relative',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  flexFlow: 'row nowrap',
  cursor: 'pointer',
  borderRadius: 10,
  marginBottom: '1rem',
  lineHeight: 1,
  whiteSpace: 'nowrap',

  '.badge': {
    display: 'flex',
    position: 'absolute',
    right: -7,
    '.red-badge': {
      width: 6,
      height: 6,
      backgroundColor: 'var(--error)',
      borderRadius: '50%',
    },
  },

  '&:hover': {
    color: 'var(--secondary)',
    backgroundColor: 'transparent',
  },

  '&:focus': {
    outline: 'none',
  },

  '& i': {
    marginRight: '0.5em',
    color: 'inherit',
  },

  '&.active': {
    color: 'var(--primary)',
    backgroundColor: 'var(--active-background)',
  },
  '@media (max-width: 991px)': {
    display: hideOnSmallScreen ? 'none' : 'flex',
  },
});

export const menuSubItem = {
  color: 'var(--theme-color)',
  backgroundColor: 'transparent',
  padding: '1em 0.8em',
  cursor: 'pointer',
  borderRadius: 4,
  display: 'block',
  whiteSpace: 'nowrap',

  '&:hover': {
    color: 'var(--theme-color)',
    backgroundColor: 'var(--gray-200)',
  },

  '&.active': {
    color: 'var(--theme-color)',
    fontWeight: 500,
  },
};

export const menuItemText = {
  display: 'block',
};

export const menuItemRightIcon = {
  color: 'var(--theme-color)',
  fontSize: 11,
};
