export const languageIconContainer = (isOpen) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  padding: '0.8em 0.8em',
  margin: '0.6rem 1.2rem',
  position: 'relative',
  flexFlow: 'row nowrap',
  cursor: 'pointer',
  borderRadius: 10,
  color: 'var(--secondary-weaker)',
  backgroundColor: 'transparent',

  '&:hover': {
    color: isOpen ? 'var(--primary)' : 'var(--secondary)',
    backgroundColor: isOpen ? 'var(--active-background)' : 'transparent',
  },

  '&:focus': {
    outline: 'none',
  },

  '&.active': {
    color: 'var(--primary)',
    backgroundColor: 'var(--active-background)',
  },
});

export const contentContainer = {
  position: 'fixed',
  bottom: 198,
  left: 13,
  display: 'flex',
  flexDirection: 'column',
  backgroundColor: 'var(--background-strong)',
  color: 'var(--secondary)',
  borderRadius: '0.25rem',
  width: 170,
  boxShadow: '0 2px 4px rgba(15, 34, 58, .12)',
  padding: '8px 0',
  '@media (max-width: 991px)': {
    bottom: 70,
    right: 'auto',
    left: 'auto'
  },
};

export const item = (selectedLanguage) => ({
  padding: '8px 24px',
  backgroundColor: selectedLanguage && 'var(--primary)',
  color: selectedLanguage ? 'var(--white)' : 'var(--secondary)',

  '&:hover': {
    backgroundColor: selectedLanguage ? 'var(--primary)' : 'var(--hover-background)',
  },

  '& > h5': {
    color: 'var(--secondary)',
  },
});
