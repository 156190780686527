import { commonStyles } from 'styles';

export const baseIcon = (props) => {
  const { color, onClick, size, filter, margin, padding, bold } = props;

  return [
    {
      fontStyle: 'normal',
      fontWeight: 'normal',
      fontVariant: 'normal',
      lineHeight: '1',
      textDecoration: 'inherit',
      textRendering: 'optimizeLegibility',
      textTransform: 'none',
      MozOsxFontSmoothing: 'grayscale',
      WebkitFontSmoothing: 'antialiased',
      fontSmoothing: 'antialiased',
      fontSize: size ?? 24,
      fontFeatureSettings: "'liga'",
      userSelect: 'none',
    },
    color && {
      color: `var(--${color}, ${color})`,
    },
    margin && {
      margin: `${margin} !important`,
    },
    padding && {
      padding: `${padding} !important`,
    },
    onClick && {
      cursor: 'pointer',
    },
    filter && {
      filter,
    },
    bold && {
      fontWeight: '900 !important',
    },
    ...commonStyles(props),
  ];
};
