import { USER_CHAT_STATUS } from 'enums';

const parseEmailToSub = {
  'bcDemoRoot@bcDemo.com': '4cd0ee01-15e4-463f-9630-0ef7f70208a9',
  'margarita.petrova@veraio.tech': '9ba9bab3-b7d3-4d25-91c5-953191a04b83',
  'bcDemo3@bcDemo.com': '6d2073d3-ae4b-4593-88ee-a0f4e13f15ce',
  'user@admin.com': '6abca353-8ede-47fc-bc53-eab93dd1ab07',
};

export const userBySub = {
  [parseEmailToSub['bcDemoRoot@bcDemo.com']]: {
    id: '4cd0ee01-15e4-463f-9630-0ef7f70208a9',
    userId: '98ac4dd1-474a-4d33-8446-6f6fec2c626e',
    name: 'bc demo root',
    email: 'bcdemoroot@bcdemo.com',
    pictureUrl: null,
    status: USER_CHAT_STATUS.Online,
  },
  [parseEmailToSub['bcDemo3@bcDemo.com']]: {
    id: '6d2073d3-ae4b-4593-88ee-a0f4e13f15ce',
    userId: '2b41203d-926d-4617-9fc7-d4ff765a439d',
    name: 'bc demo 3',
    email: 'bcdemo3@bcdemo.com',
    pictureUrl: null,
    status: USER_CHAT_STATUS.Away,
  },
  [parseEmailToSub['user@admin.com']]: {
    id: '6abca353-8ede-47fc-bc53-eab93dd1ab07',
    userId: '69fd543e-208e-4c18-b04a-adad91b0be6e',
    name: 'User Admin',
    email: 'user@admin.com',
    pictureUrl: null,
    status: USER_CHAT_STATUS.DoNotDisturb,
  },
  [parseEmailToSub['margarita.petrova@veraio.tech']]: {
    id: '9ba9bab3-b7d3-4d25-91c5-953191a04b83',
    userId: '3db8834c-21b5-4eef-9fac-2e637c1921dd',
    name: 'Margi Veraio',
    email: 'margarita.petrova@veraio.tech',
    pictureUrl: null,
    status: USER_CHAT_STATUS.Online,
  },
};
