import { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslations } from '@veraio/strank';
import { Button, Form, Icon, Input, Modal } from 'components';
import { addContactContainer } from './styles';

const AddContactModal = (props) => {
  const { showModal, onClose, onAdd } = props;
  const { getText } = useTranslations();
  const [addContact, setAddedContact] = useState({});

  const handleAddContact = (val, resetForm) => {
    console.log('Value:', val);
    onAdd(val, resetForm);
    setAddedContact({});
  };

  const handleCancelAddContact = () => {
    onClose();
    setAddedContact({});
  };
  return (
    <Modal show={showModal} onClose={onClose} css={addContactContainer}>
      <Form className="group-modal-container" values={addContact} onSubmit={(val) => handleAddContact(val)}>
        <div className="modal-title">
          <h4>{getText('addContacts')}</h4>
          <Icon iconName="close" size={16} onClick={handleCancelAddContact} />
        </div>

        <div className="modal-body">
          <Input required formId="email" placeholder={getText('enterEmail')} label={getText('email')} />
          <Input
            type="textarea"
            formId="message"
            placeholder={getText('enterMessage')}
            label={getText('invitationMessage')}
          />
        </div>

        <div className="action-container">
          {({ onSubmit }) => (
            <>
              <Button small type="secondary" className="close-button" onClick={handleCancelAddContact}>
                {getText('close')}
              </Button>
              <Button small onClick={onSubmit}>
                {getText('inviteContact')}
              </Button>
            </>
          )}
        </div>
      </Form>
    </Modal>
  );
};

AddContactModal.propTypes = {
  showModal: PropTypes.bool,
  onAdd: PropTypes.func,
  onClose: PropTypes.func,
};

export default AddContactModal;
