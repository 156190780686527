// import moment from 'moment';

export const userInfo = (user) => [
  {
    label: 'name',
    value: `${user?.firstName} ${user?.lastName}`,
  },
  {
    label: 'username',
    value: user.userName,
  },
  // {
  //   label: 'time',
  //   value: moment().format('LT'),
  // },
  {
    label: 'country',
    value: user?.country,
  },
];
