export const searchInputContainer = {
  margin: 12,
  '.search-input': {
    '.input': {
      padding: '0.85em 3.2em',
      backgroundColor: 'var(--gray-400)',
      border: 'none'
    },
    '.search-input-left-icon': {
      top: '1.2rem',
    },
  },
};
