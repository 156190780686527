import PropTypes from 'prop-types';
import { Input } from 'components';
import { searchInputContainer } from './styles';

const SearchInput = (props) => {
  const { placeholder, onChange } = props;

  return (
    <div css={searchInputContainer}>
      <Input
        small
        className="search-input"
        placeholder={`${placeholder}...`}
        leftIcon={{
          className: 'search-input-left-icon',
          iconName: 'search',
          color: 'secondary-weaker',
          size: 22,
        }}
        onChange={onChange}
      />
    </div>
  );
};

SearchInput.propTypes = {
  placeholder: PropTypes.string,
  onChange: PropTypes.func,
};

export default SearchInput;
