import PropTypes from 'prop-types';
import { Image, Col, Row, Carousel } from 'components';
import { commonStyles } from '../../../styles';
import { dotsContainer, indicatorDot } from './styles';

const ImageGallery = (props) => {
  const {
    children,
    images,
    alt,
    lg,
    md,
    sm,
    xs,
    rowGap,
    showNumberOfImage,
    carouselImagesHeight,
    boxView,
    carouselView,
    className,
  } = props;

  const hasShowImage = showNumberOfImage ? images?.slice(0, showNumberOfImage) : images;

  const getDotsTranslate = (currentIndex) => {
    switch (true) {
      case currentIndex < 4:
        return 0;
      case currentIndex > images.length - 7:
        return (images.length - 10) * -130;
      default:
        return (currentIndex - 4) * -130;
    }
  };

  return children ? (
    <div {...(className && { className })} css={commonStyles(props)}>
      {children}
    </div>
  ) : boxView ? (
    images?.length && (
      <div {...(className && { className })}>
        <Row gap={rowGap}>
          {hasShowImage?.map((el, ind) => (
            <Col key={ind} lg={lg ?? 'auto'} md={md ?? 'auto'} sm={sm ?? 'auto'} xs={xs ?? 'auto'}>
              <Image src={el} alt={alt} width={el.width || '100%'} height={el.height || 'auto'} />
            </Col>
          ))}
        </Row>
      </div>
    )
  ) : carouselView ? (
    images?.length && (
      <div {...(className && { className })}>
        <Carousel
          images={images}
          imageHeight={carouselImagesHeight}
          withCustomDots
          className="gallery-carousel"
          customDots={(handleIndicator, currentIndex) => (
            <div css={dotsContainer}>
              <div className="dots-list" style={{ transform: `translateX(${getDotsTranslate(currentIndex)}px)` }}>
                {images.map((el, index) => (
                  <div
                    key={index}
                    onClick={() => handleIndicator(index)}
                    role="presentation"
                    css={indicatorDot(index === currentIndex)}>
                    <Image src={el} />
                  </div>
                ))}
              </div>
            </div>
          )}
        />
      </div>
    )
  ) : (
    <Image src={images[0]} />
  );
};

ImageGallery.propTypes = {
  children: PropTypes.any,
  images: PropTypes.array,
  alt: PropTypes.string,
  lg: PropTypes.number,
  md: PropTypes.number,
  sm: PropTypes.number,
  xs: PropTypes.number,
  rowGap: PropTypes.number,
  showNumberOfImage: PropTypes.number,
  startImage: PropTypes.number,
  slideshow: PropTypes.bool,
  fullscreen: PropTypes.bool,
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  carouselImagesHeight: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  boxView: PropTypes.bool,
  carouselView: PropTypes.bool,
  className: PropTypes.string,
};

export default ImageGallery;
