export const fileContainer = (small) => [
  {
    backgroundColor: 'var(--theme-color)',
    borderRadius: 4,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: small ? 2 : 10,
    color: 'var(--secondary)',
    maxWidth: small ? '15rem' : '25rem',
    '.file': {
      display: 'flex',
      alignItems: 'center',

      '.icon-box': {
        backgroundColor: 'var(--primary-weaker)',
        padding: small ? 4 : 10,
        borderRadius: 4,
        color: 'var(--primary)',
        marginRight: small ? 8 : 15,
        '.file-icon': {
          verticalAlign: 'middle',
        },
      },
      '.file-info': {
        fontWeight: 600,
        textAlign: 'left',
        '.file-name': {
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          maxWidth: small ? '6rem' : '10rem',
          fontSize: small ? 12 : 14,
        },
        '.file-size': {
          fontSize: small ? 10 : 12,
          color: 'var(--secondary-weaker)',
        },
      },
    },
    '.file-actions': {
      position: 'relative',
      marginLeft: small ? 12 : 15,
      color: 'var(--secondary)',
      '.download-link': {
        marginRight: small ? 2 : 6,
        color: 'var(--secondary)',
      },
    },
  },
];
