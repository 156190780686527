export const container = {
  position: 'fixed',
  bottom: 73,
  left: 13,
  display: 'flex',
  flexDirection: 'column',
  backgroundColor: 'var(--background-strong)',
  color: 'var(--secondary)',
  borderRadius: '0.25rem',
  width: 170,
  boxShadow: '0 2px 4px rgba(15, 34, 58, .12)',
  padding: '8px 0',

  '.item': {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '8px 24px',

    '&.theme-row': {
      display: 'none'
    },

    '&:hover': {
      backgroundColor: 'var(--hover-background)',
    },

    '& > h5': {
      color: 'var(--secondary)',
    },
  },
  '@media (max-width: 991px)': {
    left: 'auto',
    right: 27,
    '.item.theme-row': {
      display: 'flex'
    }
  },
};

export const profileNavStyles = (isOpen) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  padding: '0.8em 0.8em',
  margin: '0.6rem 1.2rem',
  position: 'relative',
  flexFlow: 'row nowrap',
  cursor: 'pointer',
  borderRadius: 10,

  '&:hover': {
    backgroundColor: isOpen ? 'var(--active-background)' : 'transparent',
  },

  '&:focus': {
    outline: 'none',
  },

  '&.active': {
    backgroundColor: 'var(--active-background)',
  },

  '.avatar-image': {
    width: 30,
    height: 30,
    borderRadius: '50%',
  },
});
