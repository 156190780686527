import { isFunction } from '@veraio/core';
import PropTypes from 'prop-types';
import { actionsContainer } from './styles';
import { useEffect, useRef } from 'react';

const ActionsContainer = (props) => {
  const { children, styles, onBlur } = props;
  const containerRef = useRef();

  useEffect(() => {
    containerRef?.current && containerRef.current.focus();
  }, []);

  const handleBlur = (e) => {
    e.stopPropagation();
    const { currentTarget, relatedTarget } = e;
    if (currentTarget.contains(relatedTarget)) return;

    isFunction(onBlur) && onBlur();
  };

  return (
    <div ref={containerRef}
      css={actionsContainer(styles)}
      className="content-container"
      role="button"
      tabIndex={0}
      onBlur={handleBlur}>
      {children}
    </div>
  );
};

ActionsContainer.propTypes = {
  children: PropTypes.any,
  position: PropTypes.object,
  styles: PropTypes.object,
  onClick: PropTypes.func,
  onBlur: PropTypes.func,
  onClose: PropTypes.func,
};

export default ActionsContainer;
