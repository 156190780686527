export const ROUTES = {
  // private routes
  Chats: '/chats',
  Groups: '/groups',
  Channels: '/channels',
  MyProfile: '/my-profile',
  Settings: '/settings',

  // public routes
  AuthorizationCallback: '/authorization-callback',
  ForgotPassword: '/forgot-password',
  Logout: '/logout',
  Login: '/login',
  PrivacyPolicy: '/privacy-policy',
  TermsAndConditions: '/terms-and-conditions',
};
