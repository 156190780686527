export const imageContainer = (isRight, small) => [
  {
    display: 'inline-block',
    position: 'relative',
    borderRadius: '4px',
    '.image': {
      minWidth: small ? 80 : 120,
      minHeight: small ? 50 : 120,
      img: {
        border: `1px solid ${isRight ? 'var(--secondary-weaker)' : 'var(--background)'}`,
        borderRadius: '4px',
      },
    },
    // '.image-overflow-section': {
    //   minHeight: 'calc(100vh - 3.5em)',
    //   maxWidth: '90%',
    //   margin: '1.75em auto',
    //   display: 'flex',
    //   flexDirection: 'row',
    //   alignItems: 'center',
    // },

    '.image-actions': {
      position: 'absolute',
      bottom: 3,
      right: 5,
      '.download-link': {
        color: 'var(--white)',
        marginRight: 5,
      },
    },
  },
];

export const modalContainer = () => [
  {
    backgroundColor: 'rgba(0, 0, 0, 0.8)',
    overflow: 'hidden auto',
    display: 'block',
    maxHeight: '100vh',
    '.image-overflow-header': {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      backgroundColor: 'var(--black)',
      color: 'var(--white)',
      height: 50,
      padding: 20,
      paddingTop: 30,
      fontSize: 18,
      ul: {
        display: 'flex',
        listStyle: 'none',
        li: {
          margin: 'auto 1rem',
        },
      },
    },
    '.image-overflow-section': {
      minHeight: 'calc(100vh - 9em)',
      maxWidth: '90%',
      margin: '1.75em auto',
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
      alignItems: 'center',
    },
  },
];
