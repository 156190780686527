import { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { hasAtLeastOneRole } from '@oneecosystem/authenticate';
import { useTranslations } from '@veraio/strank';
import { getUserEmail, resetUnreadMessages, useChatStore } from 'stores';
import { displayDate, parseUuid7ToDate } from 'utils';
import { Avatar } from 'components/shared';
import { Icon } from 'components/ui';
import MessageBox from '../MessageBox';
import { chatBodyContainer, messageContainer } from './styles';

const ConversationContainer = (props) => {
  const { data } = props;
  const { getText } = useTranslations();
  const { currentChat } = useChatStore();
  const email = getUserEmail();
  const [hasUnread, setHasUnread] = useState(false);
  const container = useRef();
  const isAgentModAdmin = hasAtLeastOneRole(['SuperAdmin', 'Admin', 'Moderator', 'Agent']);

  useEffect(() => {
    container.current.scrollTop = 0;
  }, [currentChat]);

  useEffect(() => {
    handleNewMessageScroll();
  }, [data]);

  const checkForNewDayTitle = (currentMessageId, lastMessageId) => {
    if (currentMessageId === lastMessageId) return true;

    const currentDate = parseUuid7ToDate(currentMessageId);
    const lastMessageDate = parseUuid7ToDate(lastMessageId);
    return (
      currentDate.getDate() !== lastMessageDate.getDate() ||
      currentDate.getMonth() !== lastMessageDate.getMonth() ||
      currentDate.getFullYear() !== lastMessageDate.getFullYear()
    );
  };

  const getDayTitle = (currentMessageId) => {
    const currentDate = parseUuid7ToDate(currentMessageId);
    const now = new Date();

    if (
      currentDate.getDate() === now.getDate() &&
      currentDate.getMonth() === now.getMonth() &&
      currentDate.getFullYear() === now.getFullYear()
    )
      return getText('today');

    // Should add labels for last 7 days with day name - MON, TUE, ...?
    // Do we want to add for time too, if there are 10 minutes between messages?
    // Can be done when we can get old messages from BE...

    const displayDateString = displayDate(currentDate);
    return displayDateString.split(' & ').join(' at ');
  };

  const handleNewMessageScroll = () => {
    const { scrollTop } = container.current;

    if (scrollTop > -200 || data?.unreadCount === 0) {
      container.current.scrollTo({ top: 0, behavior: 'smooth' });
      resetUnreadMessages(currentChat?.id);
    } else setHasUnread(!!data?.unreadCount);
  };

  const handleShowNewMessages = () => {
    container.current.scrollTo({ top: 0, behavior: 'smooth' });
    setHasUnread(false);
  };

  const handleUserScroll = (e) => {
    const { scrollTop } = e.target;

    if (scrollTop >= -50) {
      setHasUnread(false);
      resetUnreadMessages(currentChat?.id);
    }
  };

  const getUserDisplayName = (userData) => {
    let displayName = userData?.first_name;

    if (userData?.last_name) displayName = `${displayName && displayName + ' '}${userData.last_name}`;

    return displayName || userData?.username;
  };

  return (
    <div ref={container} css={chatBodyContainer} onScroll={handleUserScroll}>
      {hasUnread && (
        <div className="new-messages">
          <div role="button" tabIndex={0} className="new-messages-button" onClick={handleShowNewMessages}>
            {getText('newMessages')}
            <Icon iconName="keyboard_arrow_down" />
          </div>
        </div>
      )}
      {data?.messages?.length ? (
        data?.messages.map((msg, index) => (
          <div key={`message-${msg.messageId}`}>
            {checkForNewDayTitle(
              msg.messageId,
              data?.messages[index !== data?.messages.length - 1 ? index + 1 : data?.messages.length - 1]?.messageId,
            ) && (
              <div className="chat-day-title">
                <span className="title">{getDayTitle(msg.messageId)}</span>
              </div>
            )}
            <div css={messageContainer(msg.author.email === email?.toLowerCase())} className="message-row">
              <div className="avatar-column">
                {(!data?.messages[index - 1] || msg.author.id !== data?.messages[index - 1]?.author.id) && (
                  <Avatar picThumbnailUrl={msg.author.pictureUrl} />
                )}
              </div>
              <div className="message-column">
                <MessageBox message={msg} isRight={msg.author.email === email?.toLowerCase()} />
                <div className="message-box-item" />
                <p className="sender-name">
                  {(!data?.messages[index - 1] || msg.author.id !== data?.messages[index - 1]?.author.id) &&
                    getUserDisplayName(msg.author)}
                </p>
              </div>
            </div>
          </div>
        ))
      ) : (
        <div className="empty-chat-container">
          <p className="no-messages-text">{getText('noMessages')}</p>
          {(isAgentModAdmin || !currentChat?.readOnly) && (
            <p className="start-chat-text">{getText('beTheFirstToSayHi', { emoji: '👋' })}</p>
          )}
        </div>
      )}
    </div>
  );
};

ConversationContainer.propTypes = {
  data: PropTypes.object,
};

export default ConversationContainer;
