export const contactsList = {
  marginTop: '1rem',
  backgroundColor: 'var(--theme-color)',
  border: '1px solid var(--border-color-weak)',

  '.contacts-title': {
    fontWeight: 600,
    backgroundColor: 'var(--background-strong)',
    padding: '1rem 1.5rem',
  },

  '.contacts-body': {
    maxHeight: '160px',
    overflow: 'auto',
    padding: '1rem',

    '.letter': {
      color: 'var(--primary)',
      fontSize: 16,
      fontWeight: 500,
      margin: '0.5em 0',
      padding: '1.2rem',
    },

    '.contacts-list': {
      paddingLeft: '1rem',
      '.contact': {
        padding: '1rem',
        display: 'flex',
        '.check-contact': {
          '.label': {
            fontSize: 14,
            fontWeight: 600,
          },
        },
      },
    },
    '.no-data-text': {
      color: 'var(--secondary)',
      textAlign: 'center',
    },
  },
};
