import { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslations } from '@veraio/strank';
import { Button, ContactsList, Form, Icon, Input, Modal } from 'components';
import { createGroupContainer } from './styles';
import { userBySub } from 'services/mocks';
import { useUserStore } from 'stores';

const CreateGroupModal = (props) => {
  const { showModal, onClose, onCreate } = props;
  const { getText } = useTranslations();
  const { email } = useUserStore();
  const [showMembers, setShowMembers] = useState(false);
  const [group, setGroup] = useState({});

  const handleToggleMembersList = () => setShowMembers((prev) => !prev);

  const handleCreateGroup = (val, resetForm) => {
    onCreate(val, resetForm);
    setGroup({});
  };

  const handleClose = () => {
    onClose();
    setGroup({});
  };

  const handleSelectMember = ({ isChecked, contactId }, setValues) => {
    setValues((prev) => ({
      ...prev,
      users: isChecked ? [...(prev.users ?? []), contactId] : (prev.users ?? []).filter((id) => id !== contactId) ?? [],
    }));
  };

  return (
    <Modal show={showModal} onClose={onClose} css={createGroupContainer}>
      <Form className="group-modal-container" values={group} onSubmit={handleCreateGroup}>
        <div className="modal-title">
          <h4>{getText('createGroup')}</h4>
          <Icon iconName="close" size={16} onClick={handleClose} />
        </div>

        <div className="modal-body">
          <Input
            required
            formId="name"
            className="modal-input"
            placeholder={getText('enterName')}
            label={getText('groupName')}
          />

          {({ values, setValues }) => {
            const { users } = values;
            return (
              <div className="members-container">
                <label>{`${getText('groupMembers')} (${users?.length ?? 0})`}</label>
                <Button small type="default" className="members-button" onClick={handleToggleMembersList}>
                  {getText('selectMembers')}
                </Button>
                {showMembers && (
                  <ContactsList
                    checked={users}
                    contacts={Object.values(userBySub).filter((mockUser) => mockUser.email !== email?.toLowerCase())}
                    onSelect={(selected) => handleSelectMember(selected, setValues)}
                  />
                )}
              </div>
            );
          }}
        </div>

        <div className="action-container">
          {({ onSubmit }) => (
            <>
              <Button small type="secondary" className="close-button" onClick={handleClose}>
                {getText('close')}
              </Button>
              <Button small onClick={onSubmit}>
                {getText('createGroup')}
              </Button>
            </>
          )}
        </div>
      </Form>
    </Modal>
  );
};

CreateGroupModal.propTypes = {
  group: PropTypes.object,
  showModal: PropTypes.bool,
  onCreate: PropTypes.func,
  onClose: PropTypes.func,
  onEdit: PropTypes.func,
};

export default CreateGroupModal;
