import { useState, forwardRef } from 'react';
import PropTypes from 'prop-types';
import 'react-dates/lib/css/_datepicker.css';
import 'react-dates/initialize';
import { isFunction, uniqueId, omit } from 'lodash-es';
import moment from 'moment';
import { DateRangePicker, SingleDatePicker, isInclusivelyBeforeDay, isInclusivelyAfterDay } from 'react-dates';
import { Icon } from 'components';
import InputContainer from '../InputContainer';
import { useDateRangePicker } from './useDateRangePicker';
import { datePickerContainer, inputDateContainer } from './styles';

const dateRangePropsToOmit = [
  'noFuture',
  'onChange',
  'showClear',
  'className',
  'withCalendarIcon',
  'formId',
  'label',
  'componentRef',
  'value',
  'validate',
  'isTouched',
];

const singlePropsToOmit = [
  'single',
  'noFuture',
  'showClear',
  'withCalendarIcon',
  'formId',
  'minDate',
  'label',
  'className',
  'componentRef',
  'onChange',
  'value',
  'validate',
  'isTouched',
];

const DatePicker = (props) => {
  const {
    noFuture = true,
    required,
    validate,
    disabled,
    single,
    small = true,
    withCalendarIcon = true,
    daySize = 30,
    numberOfMonths = 1,
    minDate,
    maxDate,
    showClear = true,
    showClearDate = true,
    displayFormat = 'DD/MM/YYYY',
    className,
  } = props;

  const { isTouched, error, singleDate, onChange, clearDates, value } = useDateRangePicker(props);
  const [focusedInput, setFocusedInput] = useState(null);

  const hasError = isTouched && error;
  const hasValidation = isFunction(validate) || required;

  const checkIsOutsideRange = (day) => {
    switch (true) {
      case noFuture && !isInclusivelyBeforeDay(day, moment()):
        return true;
      case minDate && !isInclusivelyAfterDay(day, minDate):
        return true;
      case maxDate && !isInclusivelyBeforeDay(day, maxDate):
        return true;
      default:
        return false;
    }
  };

  return (
    <InputContainer
      {...props}
      error={error}
      isTouched={isTouched}
      css={inputDateContainer}
      {...(className && { className })}>
      <div css={datePickerContainer(hasValidation, hasError, props)}>
        <div className="picker-container">
          {withCalendarIcon && <Icon material iconName="calendar_month" className="icon" size={16} />}
          {single ? (
            <SingleDatePicker
              showClearDate={showClearDate}
              disabled={disabled}
              small={small}
              daySize={daySize}
              numberOfMonths={numberOfMonths}
              {...omit(props, singlePropsToOmit)}
              date={singleDate}
              onDateChange={onChange}
              focused={focusedInput}
              onFocusChange={({ focused }) => setFocusedInput(focused)}
              isOutsideRange={checkIsOutsideRange}
              displayFormat={displayFormat}
            />
          ) : (
            <DateRangePicker
              disabled={disabled}
              small={small}
              daySize={daySize}
              numberOfMonths={numberOfMonths}
              {...omit(props, dateRangePropsToOmit)}
              startDate={value?.startDate}
              startDateId={uniqueId()}
              endDate={value?.endDate}
              endDateId={uniqueId()}
              onDatesChange={onChange}
              focusedInput={focusedInput}
              onFocusChange={setFocusedInput}
              isOutsideRange={checkIsOutsideRange}
              displayFormat={displayFormat}
            />
          )}
        </div>
        {!!showClear && (
          <div className="clearIcon">
            <Icon material size={22} iconName="close" color="gray" onClick={clearDates} />
          </div>
        )}
      </div>
    </InputContainer>
  );
};

DatePicker.propTypes = {
  required: PropTypes.bool,
  validate: PropTypes.func,
  disabled: PropTypes.bool,
  date: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  startDate: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  endDate: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  onChange: PropTypes.func,
  onDateChange: PropTypes.func,
  single: PropTypes.bool,
  noFuture: PropTypes.bool,
  small: PropTypes.bool,
  showClearDate: PropTypes.bool,
  withCalendarIcon: PropTypes.bool,
  daySize: PropTypes.number,
  numberOfMonths: PropTypes.number,
  formId: PropTypes.string,
  minDate: PropTypes.any,
  maxDate: PropTypes.any,
  label: PropTypes.string,
  showClear: PropTypes.bool,
  displayFormat: PropTypes.string,
  className: PropTypes.string,
};

const DatePickerRef = forwardRef((props, ref) => <DatePicker {...props} componentRef={ref} />);

DatePickerRef.displayName = 'DateTimePicker';

export default DatePickerRef;
