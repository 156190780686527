export const chatsContainer = {
  position: 'relative',
  overflow: 'hidden',
  height: '100vh',
  maxHeight: '100vh',
  padding: 12,
  backgroundColor: 'var(--background-stronger)',
  minWidth: 380,
  maxWidth: 380,

  '@media (max-width: 991px)': {
    minWidth: '100%',
    maxWidth: '100%',
  },

  '.page-title': {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: 12,
  },

  '.recent-users-container': {
    '.user-status-box': {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      backgroundColor: 'var(--gray-400)',
      padding: 10,
      position: 'relative',
      borderRadius: '0.75rem',
      cursor: 'pointer',
      marginRight: 16,
      width: 70,

      '.avatar-status': {
        position: 'absolute',
        top: 'calc(-18px)',
      },
      '.recent-user-name': {
        marginTop: 12,
        fontSize: 12,
        fontWeight: 800,
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        maxWidth: 58,
        minWidth: 58,
      },
    },
  },

  '.conversations-container': {
    marginTop: 12,

    h5: {
      marginLeft: 6,
      marginBottom: 12,
    },

    ul: {
      overflow: 'overlay',
      maxHeight: 'calc(100vh - 255px)',
    },

    '.no-data': {
      padding: 18,
      textAlign: 'center',
      color: 'var(--secondary-weaker)',
      fontWeight: 500,
    },
  },
};
