import { useTranslations } from '@veraio/strank';
import { Icon, Tooltip, useTheme } from 'components';
import { tooltipContainer, tooltipContent } from './styles';

const SiteMode = ({ hideOnSmallScreen }) => {
  const { getText } = useTranslations();
  const { theme, toggleTheme } = useTheme();

  return (
    <Tooltip
      content={<span>{getText('darkLightMode')}</span>}
      placement="right"
      css={tooltipContainer(hideOnSmallScreen)}
      contentClassName={tooltipContent}
      zIndex={1500}>
      <div className="icon-container">
        <Icon
          material
          size="3rem"
          iconName={`${theme}_mode`}
          onClick={toggleTheme}
        />
      </div>
    </Tooltip>
  );
};

export default SiteMode;
