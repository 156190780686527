export const groupListItem = (isOpen) => [
  {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    borderRadius: '0.5em',
    padding: 18,
    backgroundColor: isOpen ? 'var(--gray-400)' : 'var(--background-stronger)',
    marginBottom: 1,
    ':hover': {
      backgroundColor: 'var(--gray-400)',
      transition: 'all 0.2s ease-in-out',
      cursor: 'pointer',
    },

    '.group-info': {
      display: 'flex',
      alignItems: 'center',
      width: '100%',

      '.letter-avatar': {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: 'var(--primary-weaker)',
        color: 'var(--primary)',
        borderRadius: '50%',
        width: '36px',
        height: '36px',
        padding: 5,
        fontWeight: 500,
      },

      '.group-name': {
        fontSize: 14,
        fontWeight: 600,
        flexBasis: '70%',
        maxWidth: '70%',
        marginLeft: '1rem',
        paddingLeft: 6,
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
      },
    },

    '.group-tags': {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-end',
      gap: 3,
      fontSize: 11,
    },
  },
];

export const badge = () => [
  {
    fontSize: 10,
    lineHeight: '16px',
    padding: '0.25em 0.6em',
    borderRadius: '50px',
  },
];
