import { useState } from 'react';
import { useTranslations } from '@veraio/strank';
import { ActionsContainer, Collapse, Icon, Image } from 'components';
import { updateUserPresence, useUserStore } from 'stores';
import { USER_CHAT_STATUS } from 'enums';
import { updatePresence } from 'services';
import avatarLogo from 'assets/images/avatar.svg';
import { userInfo } from './config';
import { settingsContainer } from './styles';

const Settings = () => {
  const { getText } = useTranslations();
  const user = useUserStore();
  const [openContainer, setOpenContainer] = useState({ statusActions: false });

  const statusOptions = [
    user.status !== USER_CHAT_STATUS.Online && {
      label: getText('online'),
      value: USER_CHAT_STATUS.Online,
    },
    user.status !== USER_CHAT_STATUS.Away && {
      label: getText('away'),
      value: USER_CHAT_STATUS.Away,
    },
    user.status !== USER_CHAT_STATUS.DoNotDisturb && {
      label: getText('doNotDisturb'),
      value: USER_CHAT_STATUS.DoNotDisturb,
    },
  ].filter(Boolean);

  const hideContainer = () => setOpenContainer({ statusActions: false });

  const handleChangeUserStatus = (status) => {
    updatePresence(status, () => {
      updateUserPresence(status);
    });
  };

  const handleEditProfileButtonClick = () => {
    window.location.replace('https://portal.dev.oneecosystem.eu/profile/overview');
  };

  return (
    <div css={settingsContainer}>
      <div className="page-title">
        <h4>{getText('settings')}</h4>
      </div>

      <div className="user-container">
        <div className="avatar-image-container">
          <Image src={user?.pictureUrl ?? user?.picThumbnailUrl ?? avatarLogo} className="avatar-image" />
          <Icon iconName="edit" size={18} className="edit-icon" onClick={handleEditProfileButtonClick} />
        </div>
        <h5 className="name">{`${user?.firstName} ${user?.lastName}`}</h5>
        <div
          className="status-container"
          role="button"
          tabIndex={0}
          onClick={() => setOpenContainer((prev) => ({ statusActions: !prev.statusActions }))}>
          <p className="status">{user?.status}</p>
          <Icon iconName="expand_more" size={16} />
          {openContainer.statusActions && (
            <ActionsContainer styles={{ top: 190, right: 60, width: 170 }} onBlur={hideContainer}>
              {statusOptions.map((status) => (
                <div
                  key={status.value}
                  role="button"
                  tabIndex={0}
                  onClick={() => handleChangeUserStatus(status.value)}
                  className="item">
                  <p className="name">{status.label}</p>
                </div>
              ))}
            </ActionsContainer>
          )}
        </div>
      </div>

      <hr className="horizontal-line" />

      <div className="profile-description">
        <Collapse header={getText('personalInfo')}>
          <div className="user-content-container">
            {userInfo(user)?.map((el, ind) => (
              <div key={ind} className="user-content">
                <p className="label">{getText(el.label)}</p>
                <h5 className="name">{el.value}</h5>
              </div>
            ))}

            <div className="edit-button-container">
              <Icon iconName="border_color" size={14} className="edit-icon" onClick={handleEditProfileButtonClick} />
              {getText('edit')}
            </div>
          </div>
        </Collapse>

        <Collapse header={getText('help')}>
          <div className="help-container" />
        </Collapse>
      </div>
    </div>
  );
};

export default Settings;
