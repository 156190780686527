import { commonStyles } from '../../../styles';

export const containerStyle = (props) => {
  const { fullScreen, fixed } = props;

  return [
    {
      position: fixed ? 'fixed' : 'absolute',
    },
    fullScreen && {
      top: 0,
      bottom: 0,
      left: 0,
      right: 0,
    },
    ...commonStyles(props),
  ];
};
