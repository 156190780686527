import { initializeApp } from 'firebase/app';
import { getMessaging, getToken, onMessage } from 'firebase/messaging';

// Firebase config
const firebaseConfig = {
  apiKey: pushNotifications.apiKey,
  authDomain: pushNotifications.authDomain,
  projectId: pushNotifications.projectId,
  storageBucket: pushNotifications.storageBucket,
  messagingSenderId: pushNotifications.messagingSenderId,
  appId: pushNotifications.appId,
  measurementId: pushNotifications.measurementId,
};

const vapidKey = pushNotifications.vapidKey;

// Initialize Firebase
const firebaseApp = initializeApp(firebaseConfig);

// Initialize Firebase Cloud Messaging and get a reference to the service
export const messaging = getMessaging(firebaseApp);
// onMessage(messaging, (payload) => console.log('Message received from "messaging"', payload));

export const registerServiceWorker = async () => {
  if ('serviceWorker' in navigator) {
    try {
      const registrations = await navigator.serviceWorker.getRegistrations();
      const isServiceWorkerRegistered = registrations.some(
        (registration) => registration.active && registration.active.scriptURL.includes('/firebase-messaging-sw.js'),
      );

      if (!isServiceWorkerRegistered) {
        const registration = await navigator.serviceWorker.register('/firebase-messaging-sw.js');
        console.log('Service Worker registered with scope:', registration.scope);
      } else console.log('Service Worker is already registered.');
    } catch (error) {
      console.error('Error checking or registering service worker:', error);
    }
  }
};

export const setupNotifications = async () => {
  try {
    // Request permission for notifications
    const permission = await Notification.requestPermission();

    if (permission === 'granted') console.log('Notification permission granted.');
    else console.log('Notification permission denied.');

    // // Handle foreground notifications
    // onMessage(messaging, (payload) => {
    //   console.log('Foreground Message:', payload);
    //   // Handle the notification or update your UI
    // });
  } catch (error) {
    console.error('Error setting up notifications:', error);
  }
};

let errCount = 0;
export const getFcmToken = async () => {
  try {
    const token = await getToken(messaging, { vapidKey });
    errCount = 0;
    return token;
  } catch (err) {
    if (errCount < 3) {
      errCount++;

      // Retry getting token
      return await getFcmToken();
    } else console.log(err);
  }
};
