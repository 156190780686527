import { emitters } from 'enums/chat';
import { sendMessage } from './websocket';

// Add users to group chat
export const addUsersToGroup = (data, callback) => sendMessage(emitters.addUsersToGroup, data, callback);

// Remove users from group chat
export const removeUsersFromGroup = (data, callback) => sendMessage(emitters.removeUsersFromGroup, data, callback);

// Create a new group
export const createGroup = (data, callback) => sendMessage(emitters.createGroup, data, callback);

// Delete a group
export const deleteGroup = (id, callback) => sendMessage(emitters.deleteGroup, { id }, callback);

// Leave a group
export const leaveGroup = (id, callback) => sendMessage(emitters.leaveGroup, { id }, callback);
