import { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslations } from '@veraio/strank';
import { Avatar, Button, CheckButton, ContactsList, FileInput, Form, Icon, Input, Modal } from 'components';
import { createChannelContainer } from './styles';
import { userBySub } from 'services/mocks';
import { useUserStore } from 'stores';
import { omit } from '@veraio/core';

const ChannelFormModal = (props) => {
  const { data, showModal, onClose, onSubmit } = props;
  const { getText } = useTranslations();
  const { email } = useUserStore();
  const [showMembers, setShowMembers] = useState(false);
  const [channel, setChannel] = useState({ public: true });
  const isEdit = !!data;

  const handleToggleMembersList = () => setShowMembers((prev) => !prev);

  const handleCreateChannel = (val, resetForm) => {
    const newChannel = { ...omit(val, ['icon']) };
    if (val?.icon) newChannel.icon = val.icon.data;

    onSubmit(newChannel, resetForm);
  };

  const handleUpdateChannel = (val, resetForm) => {
    const newChannel = { ...omit(val, ['icon']) };
    if (val?.icon) newChannel.icon = val.icon.data;

    onSubmit(newChannel, resetForm);
  };

  const handleClose = () => {
    onClose();
    setChannel({ public: true });
  };

  const handleSelectMember = ({ isChecked, contactId }, setValues) => {
    setValues((prev) => ({
      ...prev,
      users: isChecked ? [...(prev.users ?? []), contactId] : (prev.users ?? []).filter((id) => id !== contactId) ?? [],
    }));
  };

  const toggleChannelPublicity = (value, setValues) => setValues((prev) => ({ ...prev, public: value }));

  const toggleChannelWritability = (value, setValues) => setValues((prev) => ({ ...prev, readOnly: value }));

  const getFirstSymbol = (val) => {
    // Check if name starts with emoji
    const firstSymbolEmojiRegex = /^\p{RGI_Emoji}/gv;
    if (firstSymbolEmojiRegex.test(val)) return val.match(firstSymbolEmojiRegex)[0] ?? 'C';

    return val[0]?.toUpperCase();
  };

  return (
    <Modal show={showModal} onClose={onClose} css={createChannelContainer}>
      <Form
        className="channel-modal-container"
        values={isEdit ? { ...data, ...channel } : channel}
        onSubmit={isEdit ? handleUpdateChannel : handleCreateChannel}>
        <div className="modal-title">
          <h4>{isEdit ? getText('editChannel') : getText('createChannel')}</h4>
          <Icon iconName="close" size={16} onClick={handleClose} />
        </div>

        <div className="modal-body">
          {({ values, setValues }) => {
            const { icon, name } = values;

            return (
              <div className="channel-image-selector">
                <FileInput
                  small
                  noPreview
                  className="upload-button"
                  formId="icon"
                  id="icon"
                  type="file"
                  accept="image/*"
                  label={getText('image')}
                />
                <div className="image-preview">
                  {icon ? (
                    <>
                      <Avatar picThumbnailUrl={icon?.data} className="image-container" />
                      <Icon
                        iconName="delete"
                        className="remove-button"
                        size={18}
                        onClick={() => setValues((prev) => ({ ...prev, icon: null }))}
                      />
                    </>
                  ) : (
                    <div className="letter-avatar">
                      <div>{name ? getFirstSymbol(name) : 'C'}</div>
                    </div>
                  )}
                </div>
              </div>
            );
          }}

          <Input
            required
            formId="name"
            className="modal-input"
            placeholder={getText('enterName')}
            label={getText('channelName')}
          />

          {!isEdit &&
            (({ values, setValues }) => {
              const { users } = values;
              return (
                <div className="members-container">
                  <label>{`${getText('channelMembers')} (${users?.length ?? 0})`}</label>
                  <Button small type="default" className="members-button" onClick={handleToggleMembersList}>
                    {getText('selectMembers')}
                  </Button>
                  {showMembers && (
                    <ContactsList
                      checked={users}
                      contacts={Object.values(userBySub).filter((user) => user.email !== email?.toLowerCase())}
                      onSelect={(selected) => handleSelectMember(selected, setValues)}
                    />
                  )}
                </div>
              );
            })}
          <Input
            formId="description"
            type="textarea"
            placeholder={getText('enterDescription')}
            label={getText('description')}
          />

          {({ values, setValues }) => {
            const { public: publicChannel, readOnly } = values;
            return (
              <>
                <div className="checkbox-container">
                  <CheckButton
                    onChange={(value) => toggleChannelPublicity(value, setValues)}
                    value={publicChannel}
                    checkBgColor="theme-color"
                    checkBorderColor="gray-500">
                    {getText('publicChannel')}
                  </CheckButton>
                </div>
                <div className="checkbox-container">
                  <CheckButton
                    onChange={(value) => toggleChannelWritability(value, setValues)}
                    value={readOnly}
                    checkBgColor="theme-color"
                    checkBorderColor="gray-500">
                    {getText('readOnlyChannel')}
                  </CheckButton>
                </div>
              </>
            );
          }}
        </div>

        <div className="action-container">
          {({ onSubmit: onFormSubmit }) => (
            <>
              <Button small type="secondary" className="close-button" onClick={handleClose}>
                {getText('close')}
              </Button>
              <Button small onClick={onFormSubmit}>
                {getText('submit')}
              </Button>
            </>
          )}
        </div>
      </Form>
    </Modal>
  );
};

ChannelFormModal.propTypes = {
  data: PropTypes.object,
  showModal: PropTypes.bool,
  onSubmit: PropTypes.func,
  onClose: PropTypes.func,
};

export default ChannelFormModal;
