import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useTranslations } from '@veraio/strank';
import { Button } from 'components';
import { ROUTES } from 'enums';
import { ReactComponent as Logo } from 'assets/images/logo.svg';
import { navBarHeaderContainer, navBarLeftSideContainer, navBarRightSideContainer } from './styles';

const NavBar = () => {
  const history = useHistory();
  const { getText } = useTranslations();

  return (
    <header css={navBarHeaderContainer}>
      <div css={navBarLeftSideContainer}>
        <Logo
          onClick={() => {
            history.push(ROUTES.Chats);
          }}
        />
      </div>

      <div css={navBarRightSideContainer}>
        <Button small linkTo={ROUTES.Login} className="login-styles">
          {getText('logIn')}{' '}
        </Button>
      </div>
    </header>
  );
};

NavBar.propTypes = {
  logoLink: PropTypes.string,
};

export default NavBar;
