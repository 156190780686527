export const myProfileContainer = (statusColor) => {
  return [
    {
      position: 'relative',
      height: '100vh',
      overflow: 'overlay',
      padding: 24,
      backgroundColor: 'var(--background-stronger)',
      minWidth: 380,
      maxWidth: 380,

      '@media (max-width: 991px)': {
        minWidth: '100%',
        maxWidth: '100%',
      },

      '.page-title': {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
      },

      '.user-container': {
        padding: 24,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',

        '.name': {
          marginBottom: '0.25rem',
          fontSize: '1.5rem',
        },

        '.avatar-image': {
          width: 96,
          height: 96,
          border: '1px solid var(--border-color-weak)',
          padding: '0.25rem',
          borderRadius: '50%',
          marginBottom: 24,
        },
      },

      '.profile-description': {
        padding: '24px 0',

        '.motto': {
          color: 'var(--secondary-weaker)',
          lineHeight: 1.5,
          fontSize: 15,
          marginBottom: 24,
        },

        '.user-content-container': {
          backgroundColor: 'var(--theme-color)',
          border: '1px solid var(--border-color-weak)',
          borderTop: 'none',

          '.user-content': {
            padding: '1.25rem 1.5rem',

            '.label': {
              color: 'var(--secondary-weaker)',
              marginBottom: 4,
            },
          },
        },

        '.attached-container': {
          backgroundColor: 'var(--theme-color)',
          border: '1px solid var(--border-color-weak)',
          borderTop: 'none',
          marginBottom: 4,
          padding: 16,
          height: 300,
          overflow: 'auto',

          '.scrollable-container::-webkit-scrollbar': {
            display: 'none',
          },

          '.file-content': {
            position: 'relative',
            border: '1px solid var(--border-color-weak)',
            padding: 8,
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'space-between',
            marginBottom: 4,
            borderRadius: 4,

            '.file-info-container': {
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'center',

              '.file-icon-container': {
                backgroundColor: 'var(--primary-weaker)',
                borderRadius: 4,
                width: 48,
                height: 48,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                marginRight: 8,
              },

              '.size': {
                color: 'var(--secondary-weaker)',
              },
            },

            '.icon': {
              marginRight: 4,
            },
          },
        },
      },
    },
    statusColor && {
      '.user-container': {
        '.status-container': {
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',

          '.status-indicator': {
            width: 9,
            height: 9,
            border: `3px solid var(--${statusColor})`,
            borderRadius: '50%',
            marginRight: 8,
          },

          '.status': {
            color: 'var(--secondary-weaker)',
          },
        },
      },
    },
  ];
};
