export const chatListItem = (isOpen) => [
  {
    display: 'flex',
    borderRadius: '0.5em',
    padding: '1.7rem',
    marginBottom: '1px',
    backgroundColor: isOpen ? 'var(--gray-400)' : 'var(--background-stronger)',
    ':hover': {
      backgroundColor: 'var(--gray-400)',
      transition: 'all 0.2s ease-in-out',
      cursor: 'pointer',
    },

    '.chat-info': {
      display: 'flex',
      alignItems: 'center',
      width: '80%',

      '.letter-avatar': {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: 'var(--primary-weaker)',
        color: 'var(--primary)',
        borderRadius: '50%',
        width: '36px',
        height: '36px',
        padding: 5,
        fontWeight: 500,
      },

      '.chat-data': {
        flexBasis: '70%',
        maxWidth: '70%',
        marginLeft: '1.5rem',

        '.sender-name': {
          fontSize: 14,
          fontWeight: 600,
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
        },

        '.last-message-container': {
          display: 'flex',
          alignItems: 'center',
          '.image-message-icon': {
            marginRight: 4,
          },
          p: {
            fontSize: 13,
            color: 'var(--secondary-weaker)',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
          },
        },
      },
    },

    '.chat-time': {
      fontSize: 11,
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      alignItems: 'flex-end',
      flexBasis: '20%',
      '.last-message-date': {
        color: 'var(--secondary-weaker)',
      },
      '.unread-message': {
        display: 'inline-block',
        padding: '0.25em 0.6em',
        borderRadius: '50px',
        backgroundColor: 'var(--error-weaker)',
        '.unread-message-badge': {
          fontWeight: 600,
          fontSize: 10,
          color: 'var(--error)',
          opacity: 1,
          lineHeight: '16px',
        },
      },
    },
  },
];

export const badge = () => [
  {
    fontSize: 10,
    lineHeight: '16px',
    padding: '0.25em 0.6em',
    borderRadius: '50px',
  },
];
