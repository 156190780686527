import { useState } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { useTranslations } from '@veraio/strank';
import { ActionsContainer, BodyOverflow, Icon, Image } from 'components';
import { imageContainer, modalContainer } from './styles';

const ImageBox = (props) => {
  const { image = {}, isRight, small, noActions } = props;
  const [openContainer, setOpenContainer] = useState({ imageActions: false, imageFullscreen: false });
  const { getText } = useTranslations();

  const handleOpenImage = () => {
    console.log('Open image');
    setOpenContainer((prev) => ({ ...prev, imageFullscreen: true }));
  };

  const handleDownloadImage = (e) => {
    e.stopPropagation();
    console.log('Download');
  };

  // const downloadImage = async (file) => {
  //   const imageData = await fetch(file.url);
  //   const imageBlog = await imageData.blob();
  //   const imageURL = URL.createObjectURL(imageBlog);

  //   const link = document.createElement('a');
  //   link.href = imageURL;
  //   link.download = file.name;
  //   document.body.appendChild(link);
  //   link.click();
  //   document.body.removeChild(link);
  // };

  const toggleMoreContainer = (e) => {
    e.stopPropagation();
    setOpenContainer((prev) => ({ ...prev, imageActions: !prev.imageActions }));
  };

  const handleMoreAction = (e) => {
    e.stopPropagation();
  };

  const handleCloseContainer = () => setOpenContainer((prev) => ({ ...prev, imageActions: false }));

  const handleCloseImage = () => {
    setOpenContainer((prev) => ({ ...prev, imageFullscreen: false }));
  };

  const handleZoomIn = () => {
    console.log('zoom in');
  };

  const handleZoomOut = () => {
    console.log('zoom out');
  };

  return (
    <div css={imageContainer(isRight, small)}>
      <Image
        className="image"
        src={image.thumbnailPictureUrl ?? image.pictureUrl}
        width={small ? 80 : 120}
        height={small ? 50 : 120}
        onClick={handleOpenImage}>
        {!noActions && (
          <div className="image-actions">
            <Link to={image.pictureUrl} className="download-link" download>
              <Icon iconName="download" size={small ? 16 : 24} onClick={handleDownloadImage} />
            </Link>
            <Icon iconName="more_horiz" size={small ? 16 : 24} onClick={toggleMoreContainer} />

            {openContainer.imageActions && (
              <ActionsContainer styles={{ top: 30, right: 0, width: 150 }} onBlur={handleCloseContainer}>
                <div role="button" tabIndex={0} onClick={handleMoreAction} className="item">
                  <p className="name">{getText('copy')}</p>
                  <Icon iconName="content_copy" size={16} />
                </div>
                <div role="button" tabIndex={0} onClick={handleMoreAction} className="item">
                  <p className="name">{getText('save')}</p>
                  <Icon iconName="save" size={16} />
                </div>
                <div role="button" tabIndex={0} onClick={handleMoreAction} className="item">
                  <p className="name">{getText('forward')}</p>
                  <Icon iconName="chat_paste_go" size={16} />
                </div>
                <div role="button" tabIndex={0} onClick={handleMoreAction} className="item">
                  <p className="name">{getText('delete')}</p>
                  <Icon iconName="delete" size={16} />
                </div>
              </ActionsContainer>
            )}
          </div>
        )}
      </Image>
      {openContainer.imageFullscreen && (
        <BodyOverflow fixed fullScreen css={modalContainer} zIndex={1000} onClick={handleCloseImage}>
          <header className="image-overflow-header" role="presentation" onClick={(e) => e.stopPropagation()}>
            <div>{image.name}</div>
            <ul>
              <li>
                <Icon iconName="zoom_in" onClick={handleZoomIn} />
              </li>
              <li>
                <Icon iconName="zoom_out" onClick={handleZoomOut} />
              </li>
              <li>
                <Icon iconName="close" onClick={handleCloseImage} />
              </li>
            </ul>
          </header>
          <section className="image-overflow-section">
            <img src={image.pictureUrl} alt="Some img" role="presentation" onClick={(e) => e.stopPropagation()} />
          </section>
        </BodyOverflow>
      )}
    </div>
  );
};

ImageBox.propTypes = {
  image: PropTypes.object,
  isRight: PropTypes.bool,
  small: PropTypes.bool,
  noActions: PropTypes.bool,
};

export default ImageBox;
