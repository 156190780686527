import { useEffect, useState } from 'react';
import { useTranslations } from '@veraio/strank';
import { ChannelListItem, ChannelFormModal, Icon, SearchInput, showSuccess } from 'components';
import { setCurrentChat, useChatStore } from 'stores';
import { chatTypes } from 'enums';
import { createChannel } from 'services';
import { resetUnreadMessages } from 'stores/messages';
import { channelsContainer } from './styles';
import { hasAtLeastOneRole } from '@oneecosystem/authenticate';

const Channels = () => {
  const { getText } = useTranslations();
  const { channels, otherChannels, currentChat } = useChatStore();
  const [allChannels, setAllChannels] = useState({
    myChannels: [],
    otherPublicChannels: [],
  });
  const [openChannelModal, setOpenChannelModal] = useState(false);
  const [openedChatId, setOpenedChatId] = useState(currentChat?.id);
  const [searchText, setSearchText] = useState();
  const isAdmin = hasAtLeastOneRole(['Admin', 'SuperAdmin']);

  useEffect(() => {
    getMyChannels();
  }, [channels, otherChannels]);

  useEffect(() => {
    setOpenedChatId(currentChat?.id);
  }, [currentChat?.id]);

  const getMyChannels = (options) => {
    const search = options?.search ?? searchText;

    search
      ? setAllChannels({
          myChannels: channels.filter((channel) => channel.name.toLowerCase().includes(search.toLowerCase())),
          otherPublicChannels: otherChannels.filter((channel) =>
            channel.name.toLowerCase().includes(search.toLowerCase()),
          ),
        })
      : setAllChannels({ myChannels: channels, otherPublicChannels: otherChannels });
  };

  const handleSearch = (val) => {
    setSearchText(val);
    getMyChannels({ search: val });
  };

  const handleOpenChat = (id) => {
    setCurrentChat(id, chatTypes.channel);
    resetUnreadMessages(id);
  };

  const handleOpenChannelModal = () => setOpenChannelModal(true);

  const handleCloseChannelModal = () => setOpenChannelModal(false);

  const handleCreateChannel = (data, resetForm) => {
    createChannel(data, (response) => {
      resetForm(!response.success);

      showSuccess('Successfully created a channel');
      handleCloseChannelModal();
    });
  };

  return (
    <>
      <div css={channelsContainer}>
        <div className="page-title">
          <h4>{getText('channels')}</h4>
          {isAdmin && <Icon iconName="group_add" color="secondary" onClick={handleOpenChannelModal} />}
        </div>

        <SearchInput placeholder={getText('searchChannels')} onChange={handleSearch} />

        <div className="channels-list">
          {allChannels.myChannels.length || allChannels.otherPublicChannels.length ? (
            <ul>
              {allChannels.myChannels?.map((channel) => (
                <ChannelListItem
                  key={channel.id}
                  channel={channel}
                  isSelected={openedChatId === channel.id}
                  onChatOpen={handleOpenChat}
                />
              ))}
              {allChannels.otherPublicChannels?.map((channel) => (
                <ChannelListItem
                  key={channel.id}
                  channel={channel}
                  isSelected={openedChatId === channel.id}
                  isMember={false}
                  onChatOpen={handleOpenChat}
                />
              ))}
            </ul>
          ) : (
            <div className="no-data">{getText('noChannelsFound')}</div>
          )}
        </div>
      </div>

      {isAdmin && (
        <ChannelFormModal
          showModal={openChannelModal}
          onSubmit={handleCreateChannel}
          onClose={handleCloseChannelModal}
        />
      )}
    </>
  );
};

export default Channels;
