import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { softLogout } from '@oneecosystem/authenticate';
import { Spinner } from 'components';
import { ROUTES } from 'enums';
import { disconnectWebSocket } from 'services';

const Logout = () => {
  const history = useHistory();

  useEffect(() => {
    softLogout();
    disconnectWebSocket();
    localStorage.removeItem('selectedChat');
    history.push(ROUTES.Login);
  }, []);

  return <Spinner fullScreen />;
};

export default Logout;
