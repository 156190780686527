export const forwardMessageModal = [
  {
    '.forward-modal-container': {
      backgroundColor: 'var(--background-stronger)',
      '.modal-title': {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: '0 1.5rem 2rem 1.5rem',
        borderBottom: '1px solid var(--border-color-weak)',
      },

      '.modal-body': {
        padding: '2rem 2.5rem 1rem 2.5rem',
      },

      '.action-container': {
        display: 'flex',
        justifyContent: 'flex-end',
        padding: '2rem 1.5rem 0 1.5rem',
        borderTop: '1px solid var(--border-color-weak)',
        '.close-button': {
          marginRight: 12,
          '&:hover': {
            textDecoration: 'underline',
          },
        },
      },
    },
  },
];
