import { getToken } from '@oneecosystem/authenticate';
import { useStore, createStore, showApiError } from 'components';
import { USER_CHAT_STATUS } from 'enums';
import { getOneLifeProfile } from 'services';

const userStore = createStore({
  isAuthenticated: !!getToken(),
});

export const initializeUser = async () => {
  const [lifeUser, lifeErr] = await getOneLifeProfile();
  if (lifeErr) return showApiError(lifeErr);

  userStore.setState({
    ...lifeUser,
    status: USER_CHAT_STATUS.Online,
    isAuthenticated: true,
  });
};

export const updateUserPresence = (status) => {
  userStore.setState((prev) => ({ ...prev, status }));
};

export const getUserEmail = () => (userStore.getState()?.email ?? '').toLowerCase();

export const useUserStore = (...args) => useStore(userStore, ...args);
