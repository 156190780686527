import { useState } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { useTranslations } from '@veraio/strank';
import { ActionsContainer, Icon } from 'components';
import { fileContainer } from './styles';
import { formatBytes } from 'utils';

const FileBox = (props) => {
  const { file = {}, small, noActions } = props;
  const [openContainer, setOpenContainer] = useState(false);
  const { getText } = useTranslations();

  const toggleMoreContainer = (e) => {
    e.stopPropagation();
    setOpenContainer((prev) => !prev);
  };

  const handleMoreAction = (e) => {
    e.stopPropagation();
  };

  const handleCloseContainer = () => setOpenContainer(false);

  return (
    <div css={fileContainer(small)}>
      <div className="file">
        <div className="icon-box">
          <Icon iconName="description" size={small ? 18 : 22} className="file-icon" />
        </div>
        <div className="file-info">
          <p className="file-name">{file.name}</p>
          <p className="file-size">{formatBytes(file.size)}</p>
        </div>
      </div>
      {!noActions && (
        <div className="file-actions">
          <Link to={file.sourceUrl} className="download-link" download>
            <Icon iconName="download" size={small ? 16 : 24} onClick={(e) => e.stopPropagation()} />
          </Link>
          <Icon iconName="more_horiz" size={small ? 16 : 24} onClick={toggleMoreContainer} />
          {openContainer && (
            <ActionsContainer styles={{ top: 30, right: 0, width: 150 }} onBlur={handleCloseContainer}>
              <div role="button" tabIndex={0} onClick={handleMoreAction} className="item">
                <p className="name">{getText('share')}</p>
                <Icon iconName="share" size={16} />
              </div>
              <div role="button" tabIndex={0} onClick={handleMoreAction} className="item">
                <p className="name">{getText('delete')}</p>
                <Icon iconName="delete" size={16} />
              </div>
            </ActionsContainer>
          )}
        </div>
      )}
    </div>
  );
};

FileBox.propTypes = {
  file: PropTypes.object,
  small: PropTypes.bool,
  noActions: PropTypes.bool,
};

export default FileBox;
