export const pageContainer = {
  display: 'flex',
};

export const sideNavContent = {
  '@media (max-width: 991px)': {
    minWidth: '100%',
    '.logo-navbar': {
      display: 'none',
    },
  },
};

export const mainContent = {
  '@media (max-width: 991px)': {
    minWidth: '100%',
    position: 'absolute',
    top: 0,
    left: 0,
    zIndex: 1000,
  },
};
