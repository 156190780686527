import { useEffect } from 'react';
import PropTypes from 'prop-types';
import qs from 'query-string';
import { exchangeCodeForToken } from '@oneecosystem/authenticate';
import { Spinner } from 'components';
import { ROUTES } from 'enums';
import { initializeUser } from 'stores';

const AuthorizationCallback = ({ history }) => {
  const { state, code } = qs.parse(location.search);

  useEffect(() => {
    handleSuccessLogin(code);
  }, []);

  const handleSuccessLogin = async () => {
    await exchangeCodeForToken();
    initializeUser();

    const { location } = JSON.parse(state ?? '{}');

    history.push(location ?? ROUTES.Chats);
  };

  return <Spinner />;
};

AuthorizationCallback.propTypes = {
  history: PropTypes.object,
};

export default AuthorizationCallback;
