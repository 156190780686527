import { forwardRef } from 'react';
import PropTypes from 'prop-types';
import { isFunction, isString } from 'lodash-es';
import { Icon } from '../../Icon';
import { useInput } from './useInput';
import InputContainer from '../InputContainer';
import { inputField } from './styles';

const Input = (props) => {
  const {
    type = 'text',
    required,
    validate,
    label,
    placeholder,
    leftIcon,
    rightIcon,
    inputRef,
    hasError: hasErrorProp,
    onFocus,
    onKeyDown,
    small,
    rows,
  } = props;

  const Component = type === 'textarea' ? 'textarea' : 'input';

  const { isTouched, error, ...inputAttr } = useInput(props);

  const hasError = isTouched && !!error;
  const hasValidation = isFunction(validate) || required;

  return (
    <InputContainer {...props} error={error} isTouched={isTouched}>
      {leftIcon?.iconName ? (
        <Icon
          position="absolute"
          top={`${label ? 3.25 : 1.25 - (small ? 0.45 : 0)}rem`}
          left="1.2rem"
          color="secondary-weaker"
          size={18}
          {...leftIcon}
        />
      ) : (
        leftIcon
      )}
      <Component
        ref={inputRef}
        type={type}
        rows={rows}
        className="input"
        css={inputField(hasValidation, hasError || hasErrorProp, props)}
        placeholder={isString(placeholder) ? placeholder : ''}
        {...(isFunction(onFocus) && { onFocus })}
        {...(isFunction(onKeyDown) && { onKeyDown })}
        {...inputAttr}
      />
      {rightIcon?.iconName ? (
        <Icon
          position="absolute"
          top={`${label ? 3.25 : 1.25 - (small ? 0.45 : 0)}rem`}
          right="1.2rem"
          color="secondary-weaker"
          size={18}
          {...rightIcon}
        />
      ) : (
        rightIcon
      )}
    </InputContainer>
  );
};

Input.propTypes = {
  inputRef: PropTypes.object,
  label: PropTypes.string,
  required: PropTypes.bool,
  type: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onChange: PropTypes.func,
  onFocus: PropTypes.func,
  onKeyDown: PropTypes.func,
  onError: PropTypes.func,
  placeholder: PropTypes.string,
  validate: PropTypes.func,
  pattern: PropTypes.func,
  horizontal: PropTypes.bool,
  className: PropTypes.string,
  formId: PropTypes.string,
  disabled: PropTypes.bool,
  hasError: PropTypes.bool,
  leftIcon: PropTypes.object,
  rightIcon: PropTypes.object,
  small: PropTypes.bool,
  rows: PropTypes.number,
};

const InputRef = forwardRef((props, ref) => <Input {...props} componentRef={ref} />);

InputRef.displayName = 'Input';

export default InputRef;
