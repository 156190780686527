import { commonStyles } from 'styles';

export const colorStatus = (props) => {
  const { type = 'primary', onClick } = props;

  const colorStatusTypeMap = {
    primary: {
      color: 'var(--white)',
      backgroundColor: 'var(--primary)',
    },
    info: {
      color: 'var(--info)',
      backgroundColor: 'var(--info-weak)',
    },
    blue: {
      color: 'var(--black)',
      backgroundColor: 'var(--info)',
    },
    pink: {
      color: 'var(--black)',
      backgroundColor: 'var(--pink)',
    },
    error: {
      color: 'var(--error)',
      backgroundColor: 'var(--error-weak)',
    },
    red: {
      color: 'var(--white)',
      backgroundColor: 'var(--error)',
    },
    success: {
      color: 'var(--black)',
      backgroundColor: 'var(--success-weak)',
    },
    green: {
      color: 'var(--white)',
      backgroundColor: 'var(--success)',
    },
    warning: {
      color: 'var(--black)',
      backgroundColor: 'var(--warning-weaker)',
    },
    orange: {
      color: 'var(--white)',
      backgroundColor: 'var(--warning)',
    },
    default: {
      color: 'var(--gray-400)',
      backgroundColor: 'var(--white)',
    },
  };

  return [
    colorStatusTypeMap[type],
    {
      display: 'inline-block',
      fontWeight: 500,
      padding: '4px 12px',
      borderRadius: 4,
      height: 'auto',
      fontSize: '1.2rem',
      border: type === 'default' && '1px solid var(--gray-400)',
      cursor: onClick ? 'pointer' : 'default',
    },
    ...commonStyles(props),
  ];
};
