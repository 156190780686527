import { keyframes } from '@emotion/react';
import { Types } from './Store';
import { commonStyles } from 'styles';

const generateBackColor = (type) => {
  switch (type) {
    case Types.SUCCESS:
      return 'var(--success-dark)';
    case Types.ERROR:
      return 'var(--error-light)';
    case Types.WARNING:
      return 'var(--warning-light)';
    case Types.INFO:
      return 'var(--info-light)';
    default:
      return 'var(--success-light)';
  }
};

const generateColor = (type) => {
  switch (type) {
    case Types.SUCCESS:
      return 'var(--white)';
    case Types.ERROR:
      return 'var(--error-dark)';
    case Types.WARNING:
      return 'var(--warning-dark)';
    case Types.INFO:
      return 'var(--info-dark)';
    default:
      return 'var(--success-dark)';
  }
};

const animation = keyframes`
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(0);
  }
`;

export const notification = (type, isHidden, props) => [
  {
    padding: 16,
    marginBottom: 16,
    color: 'var(--white)',
    backgroundColor: generateBackColor(type),
    borderRadius: 10,
    transform: isHidden ? 'translateX(calc(100% + 20px))' : 'none',
    transition: 'transform 0.4s ease-in-out',
    animation: `${animation} 0.4s ease-in-out`,
    cursor: 'pointer',

    '&:last-of-type': {
      marginBottom: 0,
    },
  },
  ...commonStyles(props),
];

export const notifContainer = (type) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  maxWidth: 500,
  fontSize: '1.2rem',
  color: generateColor(type),

  '.notification-text': {
    fontWeight: 500,
  },

  '& > svg, & > i': {
    marginRight: 12,
  },
});

export const allNotificationsContainer = {
  position: 'fixed',
  top: 20,
  right: 20,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-end',
  overflowY: 'auto',
  overflowX: 'hidden',
  maxHeight: 'calc(100% - 40px)',
};

export const notificationIcon = {
  marginTop: 2,
  maxHeight: 20,
  maxWidth: 20,
  color: 'var(--white)',
  marginRight: 4,
};
