import { USER_STATUS } from 'enums';

export const isUserDisabled = (user) => user?.status === USER_STATUS.Frozen || user?.status === USER_STATUS.Terminated;

export const orderUsersByLetter = (users) => {
  const orderedUsers = {};

  users?.forEach((user) => {
    const firstLetter = user.name[0].toUpperCase();

    // if other than AE symbol to others?

    if (orderedUsers[firstLetter]) orderedUsers[firstLetter].push(user);
    else orderedUsers[firstLetter] = [user];
  });

  return orderedUsers;
};
