export const textContainer = (type) => [
  {
    color: type === 'secondary' ? 'var(--white)' : 'var(--primary)',
    fontWeight: 500,
    fontSize: 13,
    '.dot': {
      background: type === 'secondary' ? 'var(--white)' : 'var(--primary)',
      borderRadius: '50%',
      display: 'inline-block',
      height: 4,
      opacity: '0.6',
      width: 4,
      marginLeft: '0.25rem',
    },
  },
];
