export const messageContainer = (isRight) => [
  {
    display: 'flex',
    flexDirection: isRight && 'row-reverse',
    marginTop: 20,

    '.action-buttons': {
      position: 'relative',
      '.more-icon': {
        cursor: 'pointer',
        color: 'var(--secondary)',
        margin: 2,
        '&:hover': {
          color: 'var(--gray-700)',
        },
      },
    },

    '.message-body': {
      backgroundColor: isRight ? 'var(--background-weak)' : 'var(--primary)',
      color: isRight ? 'var(--gray-200)' : 'var(--white)',
      borderRadius: isRight ? '8px 8px 0 8px' : '8px 8px 8px 0',
      padding: '15px 20px',
      minWidth: '8rem',

      '.message': {
        marginBottom: 10,
        whiteSpace: 'pre-wrap',
        overflowWrap: 'anywhere',
      },

      '.time-container': {
        color: isRight ? 'var(--secondary-weaker)' : 'var(--primary-muted)',
        textAlign: isRight ? 'left' : 'right',
        fontSize: 12,
        marginTop: 8,

        '.time-icon': {
          verticalAlign: 'middle',
        },

        span: {
          marginLeft: 3,
          verticalAlign: 'middle',
        },
      },
    },
  },
];
