import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslations } from '@veraio/strank';
import { Icon, Image, useTheme } from 'components';
import { ROUTES } from 'enums';
import { useUserStore } from 'stores';
import avatarLogo from 'assets/images/avatar.svg';
import { container, profileNavStyles } from './styles';

const User = () => {
  const [openContainer, setOpenContainer] = useState(false);
  const user = useUserStore();
  const history = useHistory();
  const { getText } = useTranslations();
  const { theme, toggleTheme } = useTheme();

  const hideContainer = (event = {}) => {
    event.stopPropagation();
    const { currentTarget, relatedTarget } = event;
    if (currentTarget.contains(relatedTarget)) return;
    setOpenContainer(false);
  };

  const handleLogout = () => history.push('/logout');

  return (
    <div role="button" tabIndex={0} onClick={() => setOpenContainer(true)} onBlur={hideContainer}>
      <div role="button" tabIndex={0} css={profileNavStyles(openContainer)} className={`${openContainer && 'active'}`}>
        <Image src={user?.pictureUrl ?? user?.picThumbnailUrl ?? avatarLogo} size="contain" className="avatar-image" />
      </div>

      {openContainer && (
        <div css={container}>
          <div className="item" role="button" tabIndex={0} onClick={() => history.push(ROUTES.MyProfile)}>
            <h5>{getText('profile')}</h5>
            <Icon material iconName="newsmode" color="secondary" size={18} />
          </div>

          <div className="item" role="button" tabIndex={0} onClick={() => history.push(ROUTES.Settings)}>
            <h5>{getText('settings')}</h5>
            <Icon material iconName="settings" color="secondary" size={18} />
          </div>

          <hr className="horizontal-line" />

          <div className="item theme-row" role="button" tabIndex={0} onClick={toggleTheme}>
            <h5>{getText('theme')}</h5>
            <Icon material iconName={`${theme}_mode`} color="secondary" size={18} />
          </div>

          <div className="item" role="button" tabIndex={0} onClick={handleLogout}>
            <h5>{getText('logOut')}</h5>
            <Icon material iconName="logout" color="secondary" size={18} />
          </div>
        </div>
      )}
    </div>
  );
};

export default User;
