import { commonStyles } from 'styles';

export const collapseContainer = (props) => [
  {
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: 'var(--theme-color)',
    marginBottom: 8,

    '.collapse-content': {
      transformOrigin: 'top',
    },
  },
  ...commonStyles(props),
];

export const collapseHeader = (isOpen, disabled) => [
  {
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: 12,
    width: '100%',
    border: '1px solid var(--border-color-weak)',
    borderBottom: isOpen && 'none',
    backgroundColor: 'var(--secondary-weak)',
    color: disabled ? 'var(--secondary-weaker)' : 'var(--gray-200)',

    '.title-icon': {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',

      '.icon': {
        marginRight: 8,
      },
    },

    '.arrow-icon': {
      transition: 'transform .5s ease-in-out',
    },
  },

  isOpen && {
    '.arrow-icon': {
      transform: 'rotate(180deg)',
    },
  },
];
