export const sliderContainer = (withScrollX) => [
  {
    position: 'relative',
    padding: '0 15px',

    '.scrollable-container': {
      paddingTop: '20px',
      display: 'flex',
      alignItems: 'center',
      overflow: 'auto',
      whiteSpace: 'nowrap',
      scrollBehavior: 'smooth',
      scrollbarWidth: 'none',
    },
    '.scroll-icon-left, .scroll-icon-right': {
      display: 'none',
    },
  },
  withScrollX && {
    '.scroll-icon-left, .scroll-icon-right': {
      position: 'absolute',
      top: '30%',
      zIndex: 9,
      color: 'var(--secondary)',
      opacity: 0.5,
      padding: 3,
      ':hover': {
        opacity: 1,
        cursor: 'pointer',
      },
    },

    ':hover': {
      '.scroll-icon-left, .scroll-icon-right': {
        display: 'block',
      },
    },

    '.scroll-icon-left': {
      left: -15,
    },

    '.scroll-icon-right': {
      right: -15,
    },
  },
];
