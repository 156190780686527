import { keyframes } from '@emotion/react';
import { commonStyles } from 'styles';

export const rippleBaseStyles = (props) => {
  const { backColor, focusColor } = props;

  return [
    {
      position: 'relative',
      overflow: 'hidden',
      outline: 'none',
      userSelect: 'none',
      '&:before': {
        content: focusColor ? "''" : 'none',
        display: 'block',
        opacity: '0',
        backgroundColor: 'transparent',
        position: 'absolute',
        top: '0',
        right: '0',
        bottom: '0',
        left: '0',
        zIndex: 10,
        transition: 'all 300ms',
      },
      '&:after': {
        content: "''",
        display: 'block',
        opacity: '0',
        position: 'absolute',
        borderRadius: '50%',
        backgroundColor: backColor ? `var(--${backColor}, ${backColor})` : 'var(--primary-stronger)',
        pointerEvents: 'none',
        transform: 'scale(0)',
        transformOrigin: 'center center',
        zIndex: 20,
      },
    },
    ...commonStyles(props),
  ];
};

export const onFocus = (color) => ({
  '&:before': {
    backgroundColor: color ? `var(--${color}, ${color})` : 'var(--primary-stronger)',
    opacity: '1',
  },
});

const radiusIn = keyframes`
  from {
    animation-timing-function: linear;
    transform: scale(0);
  }

  to {
    transform: scale(2.5);
  }
`;

const opacityIn = keyframes`
  from {
    animation-timing-function: linear;
    opacity: 0;
  }

  to {
    opacity: 1;
  }
`;

export const animationOn = (left, top, size) => ({
  '&:after': {
    width: size,
    height: size,
    left,
    top,
    animation: `${radiusIn} 300ms forwards, ${opacityIn} 100ms forwards`,
  },
});
