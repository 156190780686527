import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { isFunction } from 'lodash-es';
import { Icon } from '../Icon';
import { buttonContainer } from './styles';

const externalLinkExpression =
  // eslint-disable-next-line max-len
  /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/i;

const Button = (props) => {
  const {
    children,
    onClick,
    disabled,
    leftIcon,
    rightIcon,
    className,
    preventDefault,
    stopPropagation,
    linkTo,
    target = '_blank',
  } = props;
  const buttonStyles = buttonContainer(props);

  const handleClick = async (e) => {
    if (disabled) return e.preventDefault();
    preventDefault && e.preventDefault();
    stopPropagation && e.stopPropagation();
    if (isFunction(onClick)) await onClick(e);
  };

  return linkTo ? (
    externalLinkExpression.test(linkTo) ? (
      <a
        href={linkTo}
        css={buttonStyles}
        target={target}
        rel="noreferrer"
        onClick={handleClick}
        {...(className && { className })}>
        {leftIcon?.iconName ? <Icon {...leftIcon} /> : leftIcon}
        {children}
        {rightIcon?.iconName ? <Icon {...rightIcon} /> : rightIcon}
      </a>
    ) : (
      <Link to={linkTo} {...(className && { className })} css={buttonStyles} onClick={handleClick}>
        {leftIcon?.iconName ? <Icon {...leftIcon} /> : leftIcon}
        {children}
        {rightIcon?.iconName ? <Icon {...rightIcon} /> : rightIcon}
      </Link>
    )
  ) : (
    <div role="button" tabIndex={0} onClick={handleClick} css={buttonStyles} {...(className && { className })}>
      {leftIcon?.iconName ? <Icon {...leftIcon} /> : leftIcon}
      {children}
      {rightIcon?.iconName ? <Icon {...rightIcon} /> : rightIcon}
    </div>
  );
};

Button.propTypes = {
  children: PropTypes.any,
  type: PropTypes.oneOf(['primary', 'secondary', 'default', 'success', 'warning', 'info', 'danger', 'link']),
  outline: PropTypes.bool,
  clear: PropTypes.bool,
  disabled: PropTypes.bool,
  fullWidth: PropTypes.bool,
  onClick: PropTypes.func,
  preventDefault: PropTypes.bool,
  stopPropagation: PropTypes.bool,
  color: PropTypes.string,
  backColor: PropTypes.string,
  small: PropTypes.bool,
  large: PropTypes.bool,
  className: PropTypes.string,
  leftIcon: PropTypes.any,
  rightIcon: PropTypes.any,
  linkTo: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  target: PropTypes.string,
};

export default Button;
