// import {} from '../private';

export const PrivateRoutes = {
  // hotels: {
  //   internal: [
  //     {
  //       url: '/hotels/listing',
  //       component: HotelListing,
  //     },
  //     {
  //       url: '/hotels/:hotelId/checkout',
  //       component: HotelCheckout,
  //     },
  //     {
  //       url: '/hotels/:hotelId',
  //       component: HotelDetails,
  //     },
  //   ],
  // },
};
