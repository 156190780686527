import PropTypes from 'prop-types';
import { Image } from 'components/ui';
import avatarLogo from 'assets/images/avatar.svg';
import { avatarContainer } from './styles';
import { userChatStatusMap } from 'enums';

const Avatar = (props) => {
  const { pictureUrl, picThumbnailUrl, status, width, border } = props;

  return (
    <div css={avatarContainer(status && userChatStatusMap[status]?.color, width, border)}>
      <Image src={pictureUrl ?? picThumbnailUrl ?? avatarLogo} className="avatar-image" />
      {status && (
        <div className="status-container">
          <div className="status-indicator" />
        </div>
      )}
    </div>
  );
};

Avatar.propTypes = {
  pictureUrl: PropTypes.string,
  picThumbnailUrl: PropTypes.string,
  status: PropTypes.string,
  width: PropTypes.number,
  border: PropTypes.bool,
};

export default Avatar;
