export const dropDownContainer = (hasValidation, { disabled }) => ({
  position: 'relative',
  cursor: disabled ? 'not-allowed' : 'pointer',
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  marginBottom: hasValidation ? 6 : 0,
  marginTop: 8,
});

export const dropDownSelect = (isOpened, isDisabled, hasError, small) => ({
  color: isDisabled ? 'var(--gray-400)' : 'var(--secondary)',
  fontWeight: 400,
  fontSize: 13,
  border: `1px solid ${
    isOpened ? 'var(--highlight)' : hasError ? 'var(--border-color)' : isDisabled ? 'var(--border-color)' : 'var(--border-color)'
  }`,
  backgroundColor: hasError ? 'var(--gray-500)' : 'var(--gray-500)',
  borderRadius: '0.3rem',
  minHeight: small ? '3.6rem' : '4.8rem',
  padding: small ? '0.35em 0.85em' : '0.7em 0.8em',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
});

export const dropDownText = {
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
};

export const dropDownItemsCountBadge = (isSmall) => ({
  fontSize: isSmall ? '1.1rem' : '1.2rem',
  width: isSmall ? '1.7rem' : '2rem',
  height: isSmall ? '1.7rem' : '2rem',
  borderRadius: '50%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  margin: '0 0.8em 0 0.2em',
  padding: 0,
});

export const dropDownOptionsCollapse = {
  borderBottom: '1px solid var(--border-color)',
  '& .collapse-title': {
    display: 'flex',
    alignItems: 'center',
    '& .title': {
      marginRight: '0.5em',
    },
  },
};

export const dropDownOptionsContainer = (placement) => ({
  height: 'auto',
  maxHeight: 320,
  overflowY: 'auto',
  backgroundColor: 'var(--theme-color)',
  transformOrigin: placement === 'bottom' ? 'top' : 'bottom',
  transition: 'transform 400ms ease-in-out',
  cursor: 'default',
  boxShadow: '0 4px 4px rgba(0,0,0,0.3)',
  border: '1px solid var(--border-color)',
  borderRadius: '0.5rem',
});

export const dropDownOptionItem = (isSelected, small) => ({
  backgroundColor: isSelected ? 'var(--active-background)' : 'var(--theme-color)',
  color: 'var(--secondary)',
  display: 'inline-flex',
  alignItems: 'center',
  width: '100%',
  cursor: 'pointer',
  padding: small ? '0.6em' : '1.2rem',

  '&:hover': !isSelected && {
    color: 'var(--secondary)',
    backgroundColor: 'var(--hover-background)',
  },
  '&:focus': {
    outline: 'none',
  },
  '& i': {
    marginRight: 8,
  },
});

export const dropDownGroupLabel = {
  margin: 0,
  padding: '1.2rem',
  cursor: 'default',
  color: 'var(--secondary-weaker)',
  fontSize: 14,
};

export const rightIconsContainer = {
  display: 'flex',
  alignItems: 'center',
};

export const dropDownRightIcon = (isOpened) => ({
  fontSize: '1.3rem',
  transform: `rotate(${isOpened ? -180 : 0}deg)`,
  transition: 'transform 0.35s ease-out',
  cursor: 'pointer',
  display: 'inline-flex',
  color: 'var(--secondary-weaker)',
});

export const dropDownDeleteIcon = {
  display: 'inline-flex',
  fontSize: '1.3rem',
  cursor: 'pointer',
  marginRight: 8,
  color: 'var(--secondary)',

  '&:hover': {
    color: 'var(--secondary-weaker)',
  },
};

export const dropDownClearAll = ({ small }) => ({
  position: 'sticky',
  bottom: 0,
  left: 1,
  right: 1,
  padding: small ? '0 0.6em' : '0 1.2rem',
  color: 'var(--error)',
  backgroundColor: 'var(--theme-color)',
  display: 'flex',
  alignItems: 'center',
  height: small ? '3.6rem' : '4.8rem',
  cursor: 'pointer',
  borderTop: '1px solid var(--border-color)',

  '&:hover': {
    backgroundColor: 'var(--hover-background)',
  },
});

export const dropdownSearch = {
  position: 'sticky',
  top: 0,
  zIndex: 1,
  '& .input': {
    borderRadius: 0,
    border: 'none',
    borderBottom: '1px solid var(--border-color)',
  },
};

export const noResultsContainer = {
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  padding: '1.6rem 0.8rem',
};

export const noResultsIcon = {
  backgroundColor: 'var(--info)',
  borderRadius: '50%',
  padding: 8,
};

export const infiniteScroll = {
  padding: '1.6rem',
  display: 'flex',
  justifyContent: 'center',
};
