import { uuidv7 } from 'uuidv7';
import { getToken } from '@oneecosystem/authenticate';
import { isFunction } from '@veraio/core';
import { notifyStore } from './listeners';
import { showApiError, showError } from 'components';

// WebSocket initialization
export let webSocket = null;
export const listeners = new Map();

export const connectWebSocket = (fcmToken) => {
  const authToken = getToken()?.access_token;

  const protocols = ['Authorization', authToken, 'X-FCM-Token', encodeURIComponent(fcmToken)];

  webSocket = new WebSocket(apiUrls.chatApiUrl, protocols);

  webSocket.onopen = () => console.log('WebSocket connected');

  webSocket.onclose = (e) => {
    console.log('WebSocket closed', e);
    listeners.clear();
  };

  webSocket.onerror = (e) => console.log('WebSocket error:', e) || showApiError('Cannot connect to websocket');

  webSocket.onmessage = (event) => {
    const response = JSON.parse(event.data);
    if (!response.success && response.message) return showApiError(response);

    const requestId = response.header?.correlationId;
    if (listeners.has(requestId)) {
      if (!response.payload.success && response.payload.message) return showError(response.payload);

      const callback = listeners.get(requestId);
      isFunction(callback) && callback(response.payload);

      listeners.delete(requestId);
    } else notifyStore(response.header?.messageId, response.payload);
  };
};

// It may be helpful to examine the socket's bufferedAmount attribute before attempting to
// close the connection to determine if any data has yet to be transmitted on the network.
// If this value isn't 0, there's pending data still, so you may wish to wait before closing
// the connection.e
export const disconnectWebSocket = () => {
  webSocket && webSocket.close();
};

export const sendMessage = (messageId, payload, callback) => {
  const correlationId = uuidv7();
  const header = { messageId, correlationId };

  addListener(correlationId, callback);

  !webSocket || webSocket?.readyState !== 1
    ? waitForSocketConnection(() => {
        webSocket.send(JSON.stringify({ header, payload }));
      })
    : webSocket?.readyState === 1 && webSocket.send(JSON.stringify({ header, payload }));
};

const addListener = (requestId, callback) => {
  listeners.set(requestId, callback);
};

const waitForSocketConnection = (callback) => {
  setTimeout(() => {
    if (webSocket?.readyState === 1) isFunction(callback) && callback();
    else {
      console.log('waiting for connection');
      waitForSocketConnection(callback);
    }
  }, 5);
};
