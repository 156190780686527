export const inputDateContainer = {
  position: 'relative',

  '& .icon-close': {
    position: 'absolute',
    top: 6,
    right: 4,
  },
};

export const inputField = (hasValidation, hasError, { disabled, small }) => [
  {
    width: '100%',

    ' .form-control': {
      color: 'var(--secondary)',
      border: `1px solid ${hasError ? 'var(--error)' : 'var(--border-color)'}`,
      backgroundColor: hasError ? 'var(--error-light)' : 'var(--theme-color)',
      borderRadius: '0.3rem',
      minHeight: small ? '3.6rem' : '4.8rem',
      padding: small ? '0.35em 0.85em' : '0.7em 0.85em',
      width: '100%',
      transition: 'box-shadow .2s ease-in-out, border-color .2s ease-in-out',
      marginBottom: hasValidation ? 6 : 0,
      outline: 'blue',
    },
  },
  hasError && {
    '& > .form-control::placeholder': {
      color: 'var(--secondary--weaker)',
    },
  },
  disabled
    ? {
        ' > .form-control': {
          borderColor: 'var(--border-color-weak)',
          color: 'var(--gray)',
          cursor: 'not-allowed',

          '&::placeholder': {
            color: 'var(--gray)',
          },
        },
      }
    : {
        '& > .form-control:focus': {
          borderColor: 'var(--highlight)',
          outline: 'none',

          '&::placeholder': {
            color: 'var(--secondary-weaker)',
          },
        },
      },
];
