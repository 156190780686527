import { emitters } from 'enums/chat';
import { sendMessage } from './websocket';

// Send message to chat / group / channel
export const sendChatMessage = (data, callback) => sendMessage(emitters.sendMessage, data, callback);

// Search for messages across channels/groups/chats
export const search = (data, callback) => sendMessage(emitters.search, data, callback);

// Add new direct chat when opened for the first time.
export const createDirectChat = (data, callback) => sendMessage(emitters.createChat, data, callback);