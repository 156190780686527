import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Button, Form, Icon, Modal } from 'components/ui';
import { useTranslations } from '@veraio/strank';
import { userBySub } from 'services/mocks';
import { useUserStore } from 'stores';
import { forwardMessageModal } from './styles';
import { ContactsList, SearchInput } from 'components/shared';

const ForwardMessageModal = (props) => {
  const { showModal, onSend, onClose } = props;
  const { email } = useUserStore();
  const { getText } = useTranslations();
  const [sendTo, setSendTo] = useState([]);
  const [filteredContacts, setFilteredContacts] = useState([]);
  const [searchText, setSearchText] = useState();

  useEffect(() => {
    getFilteredUsers(searchText);
  }, []);

  const handleClose = () => {
    onClose();
    setSendTo([]);
  };

  const handleSelectMember = ({ isChecked, contactId }, setValues) => {
    setValues((prev) => ({
      ...prev,
      users: isChecked ? [...(prev.users ?? []), contactId] : (prev.users ?? []).filter((id) => id !== contactId) ?? [],
    }));
  };

  const getFilteredUsers = (text) => {
    const contacts = text
      ? Object.values(userBySub).filter(
          (mockUser) =>
            mockUser.email !== email?.toLowerCase() &&
            (mockUser.email === text.toLowerCase() || mockUser.name.toLowerCase().includes(text)),
        )
      : Object.values(userBySub).filter((mockUser) => mockUser.email !== email?.toLowerCase());

    setFilteredContacts(contacts);
  };

  const handleSearchContacts = (val) => {
    setSearchText(val);
    getFilteredUsers(val);
  };

  return (
    <Modal show={showModal} css={forwardMessageModal}>
      <Form className="forward-modal-container" values={{ users: sendTo }} onSubmit={onSend}>
        <div className="modal-title">
          <h4>{getText('forwardTo')}...</h4>
          <Icon iconName="close" size={16} onClick={handleClose} />
        </div>

        <div className="modal-body">
          <SearchInput placeholder={getText('searchContacts')} onChange={handleSearchContacts} />

          {({ values, setValues }) => {
            const { users } = values;
            return (
              <ContactsList
                checked={users}
                contacts={filteredContacts}
                onSelect={(selected) => handleSelectMember(selected, setValues)}
              />
            );
          }}
        </div>
        <div className="action-container">
          {({ onSubmit }) => (
            <Button small onClick={onSubmit}>
              {getText('forward')}
            </Button>
          )}
        </div>
      </Form>
    </Modal>
  );
};

ForwardMessageModal.propTypes = {
  showModal: PropTypes.bool,
  onSend: PropTypes.func,
  onClose: PropTypes.func,
};

export default ForwardMessageModal;
