import { getText } from '@veraio/strank';
import moment from 'moment';

const getUserDisplayName = (userData) => {
  let displayName = userData.first_name;

  if (userData.last_name) displayName = `${displayName && displayName + ' '}${userData.last_name}`;

  return displayName || userData.username;
};

export const userInfo = (user) => [
  {
    label: 'fullName',
    value: getUserDisplayName(user),
  },
  {
    label: 'time',
    value: moment().format('LT'),
  },
  {
    label: 'country',
    value: user?.country,
  },
];

export const chatInfo = (chat) => [
  {
    label: 'name',
    value: chat?.name,
  },
  {
    label: 'type',
    value: chat?.targetType && `${getText(chat.targetType)}`,
  },
];

export const attachedFiles = [
  {
    id: 1,
    icon: 'feed',
    title: 'Admin-A.zip',
    size: '12.5 MB',
  },
  {
    id: 2,
    icon: 'wallpaper',
    title: 'Image-1.jpg',
    size: '4.2 MB',
  },
  {
    id: 3,
    icon: 'wallpaper',
    title: 'Image-2.jpg',
    size: '3.1 MB',
  },
  {
    id: 4,
    icon: 'feed',
    title: 'Landing-A.zip',
    size: '6.7 MB',
  },
  {
    id: 5,
    icon: 'wallpaper',
    title: 'Image-2.jpg',
    size: '3.1 MB',
  },
  {
    id: 6,
    icon: 'feed',
    title: 'Landing-A.zip',
    size: '6.7 MB',
  },
];
