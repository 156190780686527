import PropTypes from 'prop-types';
import { waveAnimation } from 'styles';
import { textContainer } from './styles';

const TextWithDotsAnimation = (props) => {
  const { text, type } = props;

  return (
    <div css={textContainer(type)}>
      {text}
      <span>
        <span className="dot" css={waveAnimation()} />
        <span className="dot" css={waveAnimation({ delay: -1.1 })} />
        <span className="dot" css={waveAnimation({ delay: -0.9 })} />
      </span>
    </div>
  );
};

TextWithDotsAnimation.propTypes = {
  text: PropTypes.string,
  type: PropTypes.string,
};

export default TextWithDotsAnimation;
